import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingRentalsAndUpgradesForm } from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';


import css from './EditListingRentalsAndUpgradesPanel.css';
import { isEmpty } from 'lodash';
const { Money } = sdkTypes;

const rentalSuppliesLabel = (supplies, key) => {
  const sup = supplies.find(s => s.key === key);
  return sup ? sup.label : key;
};

const EditListingRentalsAndUpgradesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling
  } = props;

  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const { rentalItems = [] } = publicData || {};

  rentalItems.forEach(item => {
    if (Number(item.costPerRentalItem)) {
      item.costPerRentalItem = new Money(item.costPerRentalItem, config.currency);
    }
  });

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingRentalsAndUpgradesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
      <FormattedMessage id="EditListingRentalsAndUpgradesPanel.createListingTitle" />
    );

  const initialValues = valuesFromLastSubmit
    ? valuesFromLastSubmit
    : rentalItems;

  const onFormatRental = rentalItems => {
    return rentalItems.map(item => {
      const price = item.costPerRentalItem.amount;
      const quantity = Number(item.maxQuantityRental);
      const rentalItemsLabel = rentalSuppliesLabel(config.custom.rentalItems, item.rentalItem)
      return { ...item, rentalItemsLabel, costPerRentalItem: price, maxQuantityRental: quantity };
    })
  }

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <p className={css.subTitle}>
        <FormattedMessage id="EditListingRentalsAndUpgradesPanel.subTitle" />
      </p>

      <EditListingRentalsAndUpgradesForm
        className={css.form}
        currentListing={currentListing}
        initialValues={initialValues}
        onSubmit={values => {
          const { rentalItems = [] } = values;
          setValuesFromLastSubmit({ rentalItems });
          const formatRental = isEmpty(rentalItems[0])
            ? [{}]
            : onFormatRental(rentalItems);

          const rentables = rentalItems.map(i => i.rentalItem === 'other' ? i.renameRental : i.rentalItem);
          const includeOther = rentalItems.some(i => i.rentalItem === 'other');
          if (includeOther)
            rentables.push('other');

          const updateValues = {
            publicData: {
              rentalItems: formatRental,
              rentables
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        rentalItems={config.custom.rentalItems}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingRentalsAndUpgradesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingRentalsAndUpgradesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingRentalsAndUpgradesPanel;
