import React from 'react';
import css from './AboutPage.css';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

const SectionMission = (
  {
    intl
  }
) => {
  return (
    <div className={css.sectionWrapper}>
      <div className={css.missionSection}>
        <h2 className={css.missionTitle}>
          {intl.formatMessage({id: 'AboutPage.missionTitle'})}
        </h2>

        <div>
          <div className={css.mission1}>
            <p className={css.missionTitle1}>
              <FormattedMessage id={'AboutPage.missionTitle1'} />
            </p>
            <p className={css.missionContent1}>
              <FormattedMessage id={'AboutPage.missionContent1'} />
            </p>
          </div>
          <div className={css.mission2}>
            <p className={css.missionTitle2}>
              <FormattedMessage id={'AboutPage.missionTitle2'} />
            </p>
            <p className={css.missionContent2}>
              <FormattedMessage id={'AboutPage.missionContent2'} />
            </p>
          </div>
          <div className={css.mission3}>
            <p className={css.missionTitle3}>
              <FormattedMessage id={'AboutPage.missionTitle3'} />
            </p>
            <p className={css.missionContent3}>
              <FormattedMessage id={'AboutPage.missionContent3'} />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
};

SectionMission.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SectionMission);
