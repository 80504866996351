import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '..';
import {
  TRANSITION_ACCEPT,
  TRANSITION_PROVIDER_CANCEL,
  TRANSITION_CUSTOMER_CANCEL,
  txLastTransition,
} from '../../util/transaction';

import css from './TransactionPanel.css';

export const getNextTransitionMaybe = (transaction, isProvider) => {
  if (!transaction || !transaction.attributes || !transaction.attributes.lastTransition) {
    return [];
  };
  const lastTransition = txLastTransition(transaction);
  switch (lastTransition) {
    case TRANSITION_ACCEPT:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL];
      }
    default:
      return [];
  }
};

const getTransitButtonLabelId = transition => {
  switch (transition) {
    case TRANSITION_PROVIDER_CANCEL:
      return 'TransitActionButtonsMaybe.cancel';
    case TRANSITION_CUSTOMER_CANCEL:
      return 'TransitActionButtonsMaybe.cancel';
    default:
      return null;
  }
};

const getTransitErrorLabelId = transition => {
  switch (transition) {
    case TRANSITION_PROVIDER_CANCEL:
      return 'TransitActionButtonsMaybe.transitFailed';
    case TRANSITION_CUSTOMER_CANCEL:
      return 'TransitActionButtonsMaybe.transitFailed';
    default:
      return null;
  }
};

export const TransitActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    transaction,
    transitInProgress,
    transitError,
    onTransit,
    transitionNames,
    showButtons,
  } = props;
  const buttonsDisabled = transitInProgress;
  const transitErrorMessage = transitError ? (
    <p className={css.actionError}>
      <FormattedMessage id={getTransitErrorLabelId(transitionNames[0])} />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>{transitErrorMessage}</div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          inProgress={transitInProgress}
          disabled={buttonsDisabled}
          onClick={() => {
            onTransit(transaction.id, transitionNames[0]);
          }}
        >
          <FormattedMessage id={getTransitButtonLabelId(transitionNames[0])} />
        </PrimaryButton>
      </div>
    </div>
  ) : null;
};

export default TransitActionButtonsMaybe;
