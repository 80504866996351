import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionListYourSpace.css';

const SectionListYourSpace = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionListYourSpace.titleLineOneTemp" />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionListYourSpace.part1TitleTemp" />
          </h2>
          <p>
            <FormattedMessage id="SectionListYourSpace.part1TextTemp" />
          </p>
        </div>

        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionListYourSpace.part2TitleTemp" />
          </h2>
          <p>
            <FormattedMessage id="SectionListYourSpace.part2TextTemp" />
          </p>
        </div>

        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionListYourSpace.part3TitleTemp" />
          </h2>
          <p>
            <FormattedMessage id="SectionListYourSpace.part3TextTemp" />
          </p>
        </div>
      </div>
    </div>
  );
  {/* TODO: JH temporary homepage */ }
};

SectionListYourSpace.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionListYourSpace.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionListYourSpace;
