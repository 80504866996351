import React from 'react';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.css';

const CustomCategorySelectFieldMaybe = props => {
  const { name, id, categories, label, placeholder, validate } = props;


  return categories ? (
    <FieldSelect
      className={css.category}
      name={name}
      id={id}
      label={label}
      validate={validate}
    >
      <option disabled value="">
        {placeholder}
      </option>
      {categories.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomCategorySelectFieldMaybe;
