import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { ensureListing } from '../../util/data';
import { NamedLink, ResponsiveImage } from '../../components';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { formatMoney } from '../../util/currency';

import css from './SectionLocations.css';

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const SectionLocations = props => {
  const { location, intl } = props;
  const ensureLocation = ensureListing(location)
  const { title, price } = ensureLocation.attributes;

  const firstImage =
    ensureLocation.images && ensureLocation.images.length > 0 ? ensureLocation.images[0] : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const slug = title ? createSlug(title) : null;
  const linkProps = !ensureLocation.id
    ? { name: 'ListingBasePage' }
    : !slug
      ? { name: 'ListingPageCanonical', params: { id: ensureLocation.id.uuid } }
      : { name: 'ListingPage', params: { id: ensureLocation.id.uuid, slug } };

  return (
    <div className={css.locationBox}>
      <NamedLink className={css.location} {...linkProps} >
        <div className={css.imageWrapper}>
          <div className={css.aspectWrapper}>
            <LazyImage
              alt={title}
              className={css.locationImage}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
        </div>
        <div className={css.linkText}>
          <div className={css.priceValue} title={priceTitle}>
            <span className={css.nameLocation}>
              {title}
            </span>
            <FormattedMessage id="ListingPage.price" values={{ price: formattedPrice }} />
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
