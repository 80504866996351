import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import IconLogo from './IconLogo.svg';
import LogoImage from './Logo.svg';
import css from './Logo.css';
import { FormattedMessage } from '../../util/reactIntl';

const Logo = props => {
  const { className, format, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);

  if (format === 'desktop') {
    return (
      <div className={css.wrapLogo}>
        <img className={className} src={LogoImage} alt={config.siteTitle} {...rest} />
        <span className={css.beta}>
          <FormattedMessage id="Logo.beta" />
        </span>
      </div>)
      ;
  }

  return <img src={IconLogo} className={mobileClasses} alt={config.siteTitle} {...rest} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
