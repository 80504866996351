import React from 'react';
import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './ListingPage.css';

const SectionRulesMaybe = props => {
  const { className, rootClassName, rules } = props;
  const classes = classNames(rootClassName || css.sectionRulesWrapper, className);
  return rules ? (
    <div className={classes}>
      <h2 className={css.rulesTitle}>
        <FormattedMessage id="ListingPage.rulesTitle" />
      </h2>
      <p className={css.rules}>{rules}</p>
    </div>
  ) : null;
};

SectionRulesMaybe.defaultProps = { className: null, rootClassName: null };

SectionRulesMaybe.propTypes = {
  className: string,
  rootClassName: string,
  rules: string
};

export default SectionRulesMaybe;
