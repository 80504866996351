import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingPoliciesForm } from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FOOD_ALLOWED, CATERING_PACKAGE } from '../../marketplace-custom-config';

import css from './EditListingPoliciesPanel.css';
const { Money } = sdkTypes;

const EditListingPoliciesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling
  } = props;

  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const { cateringPackages = [], cateringType, minBookHour, rules } = publicData || {};

  cateringPackages.forEach(item => {
    if (item && Number(item.cost)) {
      item.cost = new Money(item.cost, config.currency);
    }
  })

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPoliciesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
      <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
    );
  const initialValues = valuesFromLastSubmit
    ? valuesFromLastSubmit
    : { cateringPackages, cateringType, minBookHour, rules };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPoliciesForm
        className={css.form}
        currentListing={currentListing}
        initialValues={initialValues}
        onSubmit={values => {
          const { cateringPackages = [], cateringType, minBookHour, rules } = values;
          setValuesFromLastSubmit({ cateringPackages, cateringType, minBookHour, rules });

          const formatRental = (cateringType === FOOD_ALLOWED || cateringType === CATERING_PACKAGE) ?
            cateringPackages.map(item => {
              const price = item.cost.amount;
              return { ...item, cost: price };
            }) : null;

          const updateValues = {
            publicData: {
              cateringPackages: formatRental,
              cateringType,
              minBookHour,
              rules
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        onManageDisableScrolling={onManageDisableScrolling}
        caterings={config.custom.caterings}
        minBookingHours={config.custom.minBookingHours}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPoliciesPanel;
