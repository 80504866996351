import React, { useState, useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldDropdownCustom, InlineTextButton, IconClose, Modal, FieldCurrencyInput } from '../../components';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import config from '../../config';
import css from './EditListingRentalsAndUpgradesForm.css';
import closeIcon from '../../assets/icons/close.png';
import { avoidPressNegative } from '../../util/avoidPressNegative';
import { OTHER } from '../../marketplace-custom-config';
import * as validators from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

const { Money } = sdkTypes;

export const EditListingRentalsAndUpgradesFormComponent = props => {
  const [onChangePrice, setOnchangePrice] = useState(false);
  const [isClickAdd, setIsClickAdd] = useState(false);
  const [isClickSubmit, setIsClickSubmit] = useState(false);
  const [initialValues, setInitialValues] = useState();

  const submittedValues = useRef();

  const { currentListing = {} } = props;
  const { rentalItems } = currentListing.attributes.publicData || {};

  const rentalItemsInitial = initialValues
    ? initialValues
    : rentalItems
      ? { rentalItems }
      : { rentalItems: [{}] };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      initialValues={rentalItemsInitial}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values
        } = formRenderProps;

        const { rentalItems = [] } = values;

        const rentalItemPlaceholderMessage = intl.formatMessage({
          id: 'EditListingRentalsAndUpgradesForm.rentalPlaceholder',
        });

        const descriptionRentalPlaceholderMessage = intl.formatMessage({
          id: 'EditListingRentalsAndUpgradesForm.descriptionRentalPlaceholder',
        });

        const closeItem = intl.formatMessage({
          id: 'EditListingRentalsAndUpgradesForm.closeItem',
        });

        const labelNameRental = intl.formatMessage({
          id: 'EditListingRentalsAndUpgradesForm.titleRentalItem',
        });

        const labelRenameRental = intl.formatMessage({
          id: 'EditListingRentalsAndUpgradesForm.titleRenameRental',
        });

        const labelDescriptionRental = intl.formatMessage({
          id: 'EditListingRentalsAndUpgradesForm.titleDescriptionRental',
        });

        const labelCostRental = intl.formatMessage({
          id: 'EditListingRentalsAndUpgradesForm.titleCostPerRentalItem',
        });

        const labelMaxQuantityRental = intl.formatMessage({
          id: 'EditListingRentalsAndUpgradesForm.titleMaxQuantityRental',
        });

        const requiredMessage = validators.required(
          intl.formatMessage({
            id: 'EditListingRentalsAndUpgradesForm.errorRental',
          })
        );
        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const onCheckInvalidFieldsForAdd = (rentalItems) => rentalItems.some(item => {
          const
            {
              rentalItem,
              descriptionRental,
              costPerRentalItem,
              maxQuantityRental,
              renameRental
            } = item || {};

          if (!rentalItem
            || !descriptionRental || !costPerRentalItem
            || !maxQuantityRental || (rentalItem === OTHER && !renameRental)) {
            return true;
          }
          return false
        })


        const onCheckInvalidFieldsForSubmit = (rentalItems) => {
          if (rentalItems.length === 1 && isEmpty(rentalItems[0])) {
            return false;
          }

          return rentalItems.some(item => {
            const
              {
                rentalItem,
                descriptionRental,
                costPerRentalItem,
                maxQuantityRental,
                renameRental
              } = item || {};

            if (!rentalItem
              || !descriptionRental || !costPerRentalItem
              || !maxQuantityRental || (rentalItem === OTHER && !renameRental)) {
              return true;
            }
            return false
          })
        }

        const pristineSinceLastSubmit = isEqual(values, submittedValues.current);

        const classes = classNames(css.root, className);
        const submitReady = (updated && (pristine || pristineSinceLastSubmit)) || ready;
        const submitInProgress = updateInProgress;

        const submitDisabled = disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        return (
          <Form
            onSubmit={(e) => {
              submittedValues.current = values;
              handleSubmit(e);
            }}
            className={classes}
          >
            <FormSpy
              onChange={() => {
                if (isClickAdd) {
                  setIsClickAdd(false);
                }

                if (isClickSubmit) {
                  setIsClickSubmit(false);
                }

                if (!initialValues) {
                  setInitialValues(values);

                }
              }}
            />
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}

            <FieldArray name="rentalItems">
              {({ fields }) => {
                return (
                  <div className={css.wrapServices}>
                    {fields.map((name, index) => {
                      return (
                        <div className={css.wrapFieldItem} key={index}>
                          <FieldDropdownCustom
                            id={`${name}.rentalItem`}
                            name={`${name}.rentalItem`}
                            categories={config.custom.rentalItems}
                            placeholder={rentalItemPlaceholderMessage}
                            intl={intl}
                            classNameSelected={css.fieldDropdown}
                            label={`${labelNameRental} ${index + 1}`}
                            classNameSelected={css.contentRental}
                            className={css.rentalItem}
                          />

                          {
                            fields.value[index].rentalItem === OTHER &&
                            <FieldTextInput
                              id={`${name}.renameRental`}
                              name={`${name}.renameRental`}
                              className={css.fieldRename}
                              type="text"
                              label={labelRenameRental}
                            />
                          }

                          <FieldTextInput
                            id={`${name}.descriptionRental`}
                            name={`${name}.descriptionRental`}
                            placeholder={fields.value[index].rentalItem === OTHER
                              ? null
                              : descriptionRentalPlaceholderMessage}
                            type="textarea"
                            className={fields.value[index].rentalItem === OTHER
                              ? css.descriptionRentalForRename
                              : css.descriptionRental}
                            label={labelDescriptionRental}
                          />

                          {!onChangePrice &&
                            <FieldCurrencyInput
                              id={`${name}.costPerRentalItem`}
                              name={`${name}.costPerRentalItem`}
                              type="number"
                              min={0}
                              currencyConfig={config.currencyConfig}
                              className={css.costPerRentalItem}
                              placeholder=' '
                              label={labelCostRental}
                            />
                          }

                          <FieldTextInput
                            id={`${name}.maxQuantityRental`}
                            name={`${name}.maxQuantityRental`}
                            type="number"
                            min={0}
                            className={css.maxQuantityRental}
                            onKeyDown={avoidPressNegative}
                            label={labelMaxQuantityRental}
                          />

                          <button
                            type="button"
                            className={css.removeItem}
                            onClick={() => {
                              setOnchangePrice(true);
                              fields.remove(index);
                              setTimeout(() => {
                                setOnchangePrice(false);
                              })
                            }
                            }
                          >
                            <span className={css.deleteMobile}>
                              <FormattedMessage id="EditListingRentalsAndUpgradesForm.delete" />
                            </span>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            </FieldArray>

            {
              onCheckInvalidFieldsForAdd(rentalItems) && isClickAdd &&
              <p className={css.error}>
                <FormattedMessage id="EditListingRentalsAndUpgradesForm.alertAddItem" />
              </p>
            }

            {
              isClickSubmit &&
              <p className={css.error}>
                <FormattedMessage id="EditListingRentalsAndUpgradesForm.alertSubmit" />
              </p>
            }

            <Button
              className={css.addingButton}
              type="button"
              onClick={() => {
                const checkingToAdd = onCheckInvalidFieldsForAdd(rentalItems);
                if (!checkingToAdd) {
                  form.mutators.push('rentalItems', {})
                }
                setIsClickAdd(true);
              }}
            >
              <FormattedMessage id="EditListingRentalsAndUpgradesForm.addRental" />
            </Button>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
              onClick={(e) => {
                const { rentalItems } = values;
                const shouldPreventSubmit = onCheckInvalidFieldsForSubmit(rentalItems)
                if (shouldPreventSubmit) {
                  e.preventDefault();
                  setIsClickSubmit(true);
                }
              }}
            >
              {saveActionMsg}
            </Button>
          </Form >
        );
      }}
    />
  )
};

EditListingRentalsAndUpgradesFormComponent.defaultProps = { fetchErrors: null };

EditListingRentalsAndUpgradesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingRentalsAndUpgradesFormComponent);
