import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
const { LatLng, LatLngBounds } = sdkTypes;


// ================ Action types ================ //

export const FETCH_LOCATIONS_REQUEST = 'app/LandingPage/FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'app/LandingPage/FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'app/LandingPage/FETCH_LOCATIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchLocationsInProgress: false,
  locations: [],
  fetchLocationsError: null
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LOCATIONS_REQUEST:
      return {
        ...state,
        fetchLocationsInProgress: true,
        locations: [],
        fetchLocationsError: null
      };
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        fetchLocationsInProgress: false,
        locations: payload
      };
    case FETCH_LOCATIONS_ERROR:
      return {
        ...state,
        fetchLocationsInProgress: false,
        fetchLocationsError: payload
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const fetchLocationsRequest = () => ({ type: FETCH_LOCATIONS_REQUEST });

export const fetchLocationsSuccess = locations => ({
  type: FETCH_LOCATIONS_SUCCESS,
  payload: locations,
});

export const fetchLocationsError = error => ({
  type: FETCH_LOCATIONS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //



export const fetchListingNearlyCurrentLocation = (bounds) => (dispatch, getState, sdk) => {
  dispatch(fetchLocationsRequest());

  const convertBounds = new LatLngBounds(
    new LatLng(bounds['_ne']['lat'], bounds['_ne']['lng']),
    new LatLng(bounds['_sw']['lat'], bounds['_sw']['lng']));
  const params = {
    bounds: convertBounds,
    include: ['author', 'images'],
    'fields.image': ['variants.default',
      'variants.landscape-crop',
      'variants.landscape-crop2x'],
    perPage: 3,
    page: 1,
  }

  return sdk.listings
    .query(params)
    .then(response => {
      const searchResult = denormalisedResponseEntities(response);
      dispatch(fetchLocationsSuccess(searchResult));
      return searchResult;
    })
    .catch(e => {
      dispatch(fetchLocationsError(storableError(e)));
      throw e;
    });
}
