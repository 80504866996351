import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_CATERING_PACKAGES } from '../../util/types';
import { lineItemsTotal } from '../../util/lineItems';
import classNames from 'classnames';

import css from './BookingBreakdown.css';


const LineItemCateringPackagesMaybe = props => {
  const { transaction, intl } = props;

  const cateringLineItems = transaction.attributes.lineItems.filter(
    item => item.code === LINE_ITEM_CATERING_PACKAGES && !item.reversal
  );

  if (cateringLineItems.length === 0) {
    return null;
  }


  const subTotal = lineItemsTotal(cateringLineItems);
  const formattedSubTotal = cateringLineItems.length > 0 ? formatMoney(intl, subTotal) : null;


  const { protectedData } = transaction.attributes;
  const { cateringDataMaybe = [] } = protectedData || {};
  return (
    <>
      <p className={css.titleSection}>
        <FormattedMessage id="BookingBreakdown.sectionCatering" />
      </p>
      {
        cateringLineItems.map((item, index) => {
          const price = formatMoney(intl, item.lineTotal);
          const label = intl.formatMessage(
            { id: 'BookingBreakdown.labelCatering' },
            { name: cateringDataMaybe[index].name, quantity: cateringDataMaybe[index].quantity }
          );
          return (
            <div className={classNames(css.lineItem, css.lineItemCustomPricing) } key={index}>
              <span className={css.itemLabel}>{label}</span>
              <span className={css.itemValue}>{price}</span>
            </div>
          );
        })
      }

      <hr className={css.totalDivider} />

      <div className={css.lineItem}>
        <span className={css.itemLabelBold}>
          <FormattedMessage id="BookingBreakdown.subTotal" />
        </span>
        <span className={css.itemValueBold}>{formattedSubTotal}</span>
      </div>
    </>
  );
};

LineItemCateringPackagesMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCateringPackagesMaybe;
