import { types as sdkTypes } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import {
  LINE_ITEM_CATERING_PACKAGES,
  LINE_ITEM_SUPPLIES,
} from './types';

const { Money } = sdkTypes;

export const lineItemsTotal = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount, currency);
};

// cateringPackages, guestCateringQuantity: Data that the buyer has entered in Booking Form
export const createCateringPackagesLineItems = (cateringPackages, guestCateringQuantity, currency) => {
  const cateringLineItemMaybe = [];
  const cateringDataMaybe = [];
  if (cateringPackages.length > 0) {
    for (let i = 0; i < cateringPackages.length; i++) {
      const cateringItem = JSON.parse(cateringPackages[i]);
      const { cost } = cateringItem;
      const lineItem = {
        code: LINE_ITEM_CATERING_PACKAGES,
        includeFor: ['customer', 'provider'],
        unitPrice: new Money(cost, currency),
        quantity: guestCateringQuantity,
        lineTotal: new Money(new Decimal(cost).times(guestCateringQuantity).toNumber(), currency),
        reversal: false,
      };
      cateringLineItemMaybe.push(lineItem);
      const { unitPrice, lineTotal, ...rest } = lineItem;

      const cateringDataItem = {
        ...cateringItem,
        ...rest,
        unitPrice: { amount: unitPrice.amount / 100, currency: unitPrice.currency },
        lineTotal: { amount: lineTotal.amount / 100, currency: unitPrice.currency}
      };
      cateringDataMaybe.push(cateringDataItem)
    }
  }
  return {
    'lineItem': cateringLineItemMaybe,
    'protectedData': cateringDataMaybe
  }
}

// arraySupplies: Data that the buyer has entered in Booking Form
// rentalItems: Data that the seller has entered in Create Listing Page
export const createSuppliesLineItems = (arraySupplies, rentalItems, currency) => {
  const suppliesLineItemMaybe = [];
  const suppliesDataMaybe = [];
  if (arraySupplies.length > 0) {
    for (let i = 0; i < arraySupplies.length; i++) {
      if (arraySupplies[i]) {
        const { costPerRentalItem } = rentalItems[i];
        const lineItem = {
          code: LINE_ITEM_SUPPLIES,
          includeFor: ['customer', 'provider'],
          unitPrice: new Money(costPerRentalItem, currency),
          quantity: parseInt(arraySupplies[i]),
          lineTotal: new Money(new Decimal(costPerRentalItem).times(arraySupplies[i]).toNumber(), currency),
          reversal: false,
        };
        suppliesLineItemMaybe.push(lineItem);
        const { unitPrice, lineTotal, ...rest } = lineItem;
        const supplyDataItem = {
          ...rentalItems[i],
          ...rest,
          quantity: arraySupplies[i],
          unitPrice: { amount: unitPrice.amount / 100, currency: unitPrice.currency },
          lineTotal: { amount: lineTotal.amount / 100, currency: unitPrice.currency}
        };
        suppliesDataMaybe.push(supplyDataItem)
      }
    }
  }
  return {
    'lineItem': suppliesLineItemMaybe,
    'protectedData': suppliesDataMaybe
  }
}
