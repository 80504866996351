import React from 'react';
import css from './ListingPage.css';
import { FormattedMessage } from 'react-intl';
import { VENUE_SIZE_UNIT } from '../../util/types';
import PropTypes from 'prop-types';

const SectionApproximateSize = ({ approximateSize }) => {
  return (
    <div className={css.sectionApproximateSizeWrapper}>
      <h2 className={css.approximateSizeTitle}>
        <FormattedMessage id="ListingPage.approximateSizeTitle" />
      </h2>
      <p>{approximateSize} {VENUE_SIZE_UNIT}</p>
    </div>
  )
};

const { number } = PropTypes;

SectionApproximateSize.propTypes = {
  approximateSize: number
};

export default SectionApproximateSize
