import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <h2 className={css.title}>1.0 INTRODUCTION</h2>
      <p>
        OtherVenue uses the personal information you share with us to provide
        services to you and to try to deliver an exceptional user experience.
        We are committed to protecting the personal information we collect from you,
        our members who browse and book venues on OtherVenue, Listing Owners who
        use the OtherVenue Platform to promote and manage your listing, and partners,
        service providers and other visitors to the OtherVenue website.
      </p>

      <p>
        This Privacy Policy will help you understand how we handle your personal
        information, as it relates to your access to and use of the OtherVenue Platform.
      </p>

      <h3>1.1 Definitions</h3>
      <p>

        Any undefined terms in this Privacy Policy will be defined precisely as they are
        in our
        <NamedLink name="TermsOfServicePage" className={css.link}>
          Terms of Use
        </NamedLink>
       (“Terms”). Please refer to the Terms for clarification regarding
        any such terms.
      </p>

      <h2 className={css.title}>2.0 GUIDING PRINCIPLES</h2>
      <p>
        OtherVenue's
        <NamedLink name="AboutPage" className={css.linkUnderline}>
          mission
        </NamedLink>

        to deliver incredible value to our customers and to the world
        around us is the foundation that guides how we think about everything we do, including
        how we secure and protect your private information.
      </p>
      <p>
        Your information belongs to you, and we understand that any personal details you've shared
        with us is shared for the purpose of advancing your interests as they relate to use of
        the OtherVenue platform. Our goal is to collect and retain only the information we need
        to provide our services to you and to continuously improve the OtherVenue Platform.
      </p>
      <p>
        If a third party requests your personal information, we will refuse to share it unless
        you have given us permission or we are legally obligated to share. We'll always respond
        to all privacy-related inquiries we receive as quickly as possible.
      </p>

      <h2 className={css.title}>3.0 INFORMATION WE COLLECT</h2>

      <p>
        • Information you give us or share on the OtherVenue Platform about you, your business,
        your space or venue, and your event or activity. This may include, but is not limited
        to, your name, company name, address, and email address.
      </p>

      <p>
        • Payment information you provide, such as your  bank account or credit card number.
      </p>

      <p>
        • Information about how you access and use the OtherVenue website, including information
        about the device and browser you use, your network connection, and your IP address.
        Like most sites, we collect some of this information by using “cookies” or other similar
        technologies.  In some cases we or our business partners may associate the information we
        collect via cookies or similar technology with your personal information.  While you may
        disable the usage of cookies through your browser settings, the OtherVenue platform currently
        does not respond to a “Do Not Track” signal in the HTTP header from your browser or mobile
        application due to lack of standardization regarding how that signal should be interpreted.
      </p>

      <p>
        • Copies of government-issued identification or other personally identifiable pictures or documents
        that you provide to verify your identification or your affiliation to your venue or business.
      </p>

      <p>
        • Information others, including third party services such as Google and Facebook (e.g. when
        you login or connect using their service) provide about you (e.g. reviews, references,
        registration and profile information). Information provided by third party services varies
        and is controlled by you via the privacy settings of that service. We do not control,
        supervise or respond for how third parties sending this information process your personal
        information, and any information request regarding the disclosure of your personal
        information to us should be directed to those third parties.
      </p>

      <p>
        • As permitted by applicable laws, OtherVenue may obtain background information and
        reports from public records of criminal convictions or sex offender registrations.
        For members outside of the United States, as permitted by applicable laws and with
        your consent where required, OtherVenue may obtain the local version of police,
        background or registered sex offender checks. We may use your information, including
        your full name and date of birth, to obtain such reports.
      </p>

      <p>
        • To the extent permitted by applicable law, we may receive additional information
        about you, such as demographic data or information to help detect fraud and safety
        issues, from third party service providers and/or partners, and combine it with
        information we have about you. For example, we may receive background check results
        (with your consent where required) or fraud warnings from service providers like
        identity verification services for our fraud prevention and risk assessment efforts.
        We may receive information about you and your activities on and off the OtherVenue
        platform through partnerships, or about your experiences and interactions from our
        partner ad networks.
      </p>

      <h2 className={css.title}>4.0 HOW WE USE THE INFORMATION WE COLLECT</h2>

      <p>
        • To enable you to use our platform and to access other related services, including,
        for example: creating a profile or a listing; confirming your identity; booking a
        listing; communicating with other members and visitors; and resolving issues with
        the platform.
      </p>

      <p>
        • To charge for our services and issue payments and refunds.
      </p>

      <p>
        • To provide customer service and send you support messages.
      </p>

      <p>
        • To process referral invitations.
      </p>

      <p>
        • To operate, protect and improve our platform and other related services
        (e.g., using analytics and research to identify ways to make OtherVenue
        easier to use or navigate) and to ensure you are able to access and use
        our platform.

      </p>

      <p>
        • To personalize the platform for you and to advertise and market products
        or features to you.
      </p>

      <p>
        • To verify your identity, account and/or listing ownership to protect you
        and other members against crime such as identity theft or fraud.
      </p>

      <p>
        • To detect and prevent any form of fraud or crime and to enforce our
        <NamedLink name="TermsOfServicePage" className={css.link}>
          Terms of Use Policy.
        </NamedLink>
        and any other OtherVenue policy. In a small number of cases,
        the outcome of such detection and prevention efforts may result in suspension
        or termination of membership if we identify activity or information that we
        think poses a safety or other risk to you, the OtherVenue community, the
        OtherVenue Platform, to third parties or partners, or which otherwise violate
        our
        <NamedLink name="TermsOfServicePage" className={css.link}>
          Terms of Use Policy.
        </NamedLink>
      </p>

      <p>
        • To comply with legal requirements.
      </p>

      <h2 className={css.title}>5.0 HOW WE SHARE YOUR INFORMATION</h2>

      <p>
        We do not sell your personal information. To provide our services, facilitate
        communication between users, and process bookings and payments, we may need to
        share your personal information with other users and/or with third-party service
        providers. This includes:
      </p>

      <p>
        • Sharing your OtherVenue user and published listing profile information (e.g. name,
        bio, reviews) with other users. We do not share your payment information, birthdate,
        mailing address, and personal email address or phone number with other users.
      </p>

      <p>
        • Sharing your personal information (e.g. bank account and credit card) with service
        providers  in order to facilitate payments, refunds and to analyze and resolve any
        disputes or fraud claims.
      </p>

      <p>
        • Sharing in response to a legal obligation or when otherwise necessary to exercise
        or defend legal claims, protect against harm to our rights, property, interests,
        or safety or the rights, property, interests, or safety of you, members of the
        OtherVenue community, third-parties, or the public as required or permitted by law.
      </p>

      <p>
        • Sharing to help us conduct marketing and advertising.
      </p>

      <p>
        • With your permission or upon your direction, we may disclose your personal information
        to interact with a third party or for other purposes not described here.
      </p>

      <p>
        • We may share your personal information while negotiating or in relation to a change
        of corporate control such as a restructuring, merger, or sale of our assets.
      </p>

      <h2 className={css.title}>6.0 CHILDREN'S DATA</h2>
      <p>
        OtherVenue is not directed to children, and we don’t knowingly collect personal
        information from children under the age of 16. If we learn that a child under 16
        has given us personal information, we will take steps to delete that information.
        If you believe that a child under the age of 16 has given us personal information,
        please contact our customer support team.
      </p>

      <h2 className={css.title}>7.0 YOUR RIGHTS CONCERNING YOUR DATA</h2>
      <h3>7.1 Everyone</h3>

      <p>
        As an OtherVenue Platform user and/or member of the OtherVenue community, you always
        have the ability to access and delete most personal data at the
        <NamedLink name="LandingPage" className={css.linkUnderline}>
          OtherVenue website URL
        </NamedLink>
        by logging in to your account settings and profile. To delete your account, or
        if you need help accessing or deleting your personal information, you can contact
        support@othervenue.com and we will assist you with your request.
      </p>

      <p>
        In rare cases, where there is an issue with your account related to trust, safety,
        or fraud, we may be unable to delete your account right away. When we delete your
        account, we may retain certain information for legitimate business purposes or to
        comply with legal or regulatory obligations. For example, we may be obligated to
        retain your information as part of an open legal claim. When we retain such data,
        we do so in ways designed to prevent its use for other purposes.
      </p>

      <p>
        To unsubscribe from promotional or commercial emails, you can click the unsubscribe
        link in those emails.
      </p>

      <p>
        You can modify your cookie settings on your browser, but if you delete or choose not
        to accept our cookies, you may miss out on some features of the OtherVenue Platform.
      </p>

      <h3>7.2 California Residents</h3>

      <p>
        The California Consumer Privacy Act provides some California residents with the
        additional rights listed below. To request access to or deletion of your personal
        information, or to exercise any other data rights under California law, please
        contact us at support@othervenue.com.  Please include your full name, email address,
        and phone number associated with your use of the OtherVenue Platform, along with why
        you are writing, so we can process your request efficiently.
      </p>

      <p>
        <strong>Right to Know.</strong> You have the right to know and see what data we have collected about
        you over the past 12 months, including:
      </p>

      <div className={css.wrapNoMargin}>
        <p>
          • The categories of personal information we have collected about you;
       </p>

        <p>
          • The categories of sources from which the personal information is collected;
      </p>

        <p>
          • The business or commercial purpose for collecting your personal information;
        </p>

        <p>
          • The categories of third parties with whom we have shared your personal information; and
        </p>

        <p>
          • The specific pieces of personal information we have collected about you.
        </p>
      </div>

      <p>
        <strong> Right to Delete.</strong> You have the right to request that we delete the personal
        information we have collected from you (and direct our service providers to
        do the same). There are a number of exceptions, however, that include, but
        are not limited to, when the information is necessary for us or a third party
        to do any of the following:
      </p>

      <div className={css.wrapNoMargin}>
        <p>
          • Complete your transaction;
         </p>

        <p>
          • Provide you a good or service;
        </p>

        <p>
          • Perform a contract between us and you;
        </p>

        <p>
          • Protect your security and prosecute those responsible for breaching it;
        </p>

        <p>
          • Fix our system in the case of a bug;
        </p>

        <p>
          • Protect the free speech rights of you or other users;
        </p>

        <p>
          • Comply with the California Electronic Communications Privacy Act (Cal.
          Penal Code § 1546 et seq.);
        </p>

        <p>
          • Engage in public or peer-reviewed scientific, historical, or statistical
          research in the public interests that adheres to all other applicable
          ethics and privacy laws;
        </p>

        <p>
          • Comply with a legal obligation; or
        </p>

        <p>
          • Make other internal and lawful uses of the information that are compatible
          with the context in which you provided it.
        </p>
      </div>

      <p>
        <strong>Other Rights.</strong> You can request certain information about our disclosure of
        personal information to third parties for their own direct marketing purposes
        during the preceding calendar year. This request is free and may be made once
        a year. You also have the right not to be discriminated against for exercising
        any of the rights listed above.
      </p>

      <h2 className={css.title}>8.0 LINKS TO THIRD PARTY WEBSITES.</h2>
      <p>
        The OtherVenue Platform may contain links to third-party websites. Those websites
        may have privacy policies that differ from ours. We are not responsible for those
        websites, and we recommend that you review their policies. Please contact those
        websites directly if you have any questions about their privacy policies.
      </p>

      <h2 className={css.title}>9.0  CHANGES TO THIS PRIVACY POLICY</h2>
      <p>
        We may update this policy from time to time as the OtherVenue Platform changes
        and privacy law evolves. If we update it, we will do so online, and if we make
        material changes, we will let you know through the OtherVenue Platform or by
        some other method of communication like email. When you use OtherVenue, you are
        agreeing to the most recent terms of this policy.
      </p>

      <h2 className={css.title}>10.0 CONTACT US</h2>
      <p>
        If you have any questions or concerns about your privacy or anything in this policy,
        including if you need to access this policy in an alternative format, we encourage
        you to contact our customer support team at support@othervenue.com.
      </p>



    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
