import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import css from './AboutPage.css';
import logo from '../../assets/Logo.svg';

const SectionHero = (
  {
    intl
  }
) => {
  return (
    <div className={css.heroSection}>
      <div className={css.heroContent}>
        <img
          src={logo}
          className={css.aboutUsLogo}
          alt={intl.formatMessage({id: 'Logo.otherVenueLogo'})}
        />
        <p className={css.logoText}>
          <FormattedMessage id={'AboutPage.logoText'} />
        </p>
        <p className={css.heroText}>
          <FormattedMessage id={'AboutPage.heroTitle'} />
        </p>
      </div>
    </div>
  )
};

SectionHero.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SectionHero)
