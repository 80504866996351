import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldDropdownCustom, FieldCurrencyInput } from '../../components';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import config from '../../config';
import css from './EditListingPoliciesForm.css';
import closeIcon from '../../assets/icons/close.png';
import * as validators from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { FOOD_ALLOWED, CATERING_PACKAGE } from '../../marketplace-custom-config';

const { Money } = sdkTypes;

export const EditListingPoliciesFormComponent = props => {
  const [onChangePrice, setOnchangePrice] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [isClickAdd, setIsClickAdd] = useState(false);

  const { currentListing = {} } = props;
  const { publicData } = currentListing.attributes || {};

  const { cateringPackages, cateringType, minBookHour, rules } = publicData || {};

  const cateringPackagesInitial = initialValues
    ? initialValues
    : { cateringPackages, cateringType, minBookHour, rules };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      initialValues={cateringPackagesInitial}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
          caterings,
          minBookingHours,
        } = formRenderProps;

        const {
          cateringType,
          rules,
          cateringPackages = [],
        } = values || {};

        const cateringPlaceholder = intl.formatMessage({
          id: 'EditListingPoliciesForm.cateringPlaceholder',
        });

        const minBookingPlaceholder = intl.formatMessage({
          id: 'EditListingPoliciesForm.minBookingPlaceholder',
        });

        const labelCatering = intl.formatMessage({
          id: 'EditListingPoliciesForm.labelCatering',
        });

        const labelMinBooking = intl.formatMessage({
          id: 'EditListingPoliciesForm.labelMinHours',
        });

        const rulesPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.rulesPlaceholder',
        });

        const rulesLabelMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.rulesLabel',
        });

        const labelNameCatering = intl.formatMessage({
          id: 'EditListingPoliciesForm.titleCateringPackage',
        });
        const labelDescriptionCatering = intl.formatMessage({
          id: 'EditListingPoliciesForm.titleDescription',
        });

        const labelCostCatering = intl.formatMessage({
          id: 'EditListingPoliciesForm.titleCostPerPerson',
        });

        const closeItem = intl.formatMessage({
          id: 'EditListingPoliciesForm.closeItem',
        });

        const requiredMessage = validators.required(
          intl.formatMessage({
            id: 'EditListingPoliciesForm.errorCatering',
          })
        );
        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;

        const submitDisabled = disabled || submitInProgress;

        const { updateListingError, showListingsError } = fetchErrors || {};

        const onCheckInvalidFields = (cateringPackages) => cateringPackages.some(item => {
          const
            {
              name,
              description,
              cost,
            } = item || {};

          if (!name || !description || !cost) {
            return true;
          }
          return false
        })

        return (
          <Form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            className={classes}
          >
            <FormSpy
              onChange={() => {
                const { cateringType, cateringPackages } = values;
                if ((cateringType === FOOD_ALLOWED || cateringType === CATERING_PACKAGE) && !cateringPackages) {
                  values.cateringPackages = [{}];
                  setInitialValues(values);
                }

                if (isClickAdd) {
                  setIsClickAdd(false);
                }
              }}
            />
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}

            <FieldDropdownCustom
              id="cateringType"
              name="cateringType"
              categories={caterings}
              intl={intl}
              form={form}
              className={css.cateringType}
              classNameSelected={css.contentCatering}
              labelClass={css.labelCateringDropdown}
              label={labelCatering}
              validate={requiredMessage}
              placeholder={cateringPlaceholder}
            />

            {(cateringType === FOOD_ALLOWED || cateringType === CATERING_PACKAGE) &&
              <>
                < FieldArray name="cateringPackages">
                  {({ fields }) => {
                    return (
                      <div className={css.wrapServices}>
                        {fields.map((name, index) => {
                          return (
                            <div className={css.wrapFieldItem} key={index}>

                              <FieldTextInput
                                id={`${name}.name`}
                                name={`${name}.name`}
                                className={css.nameCatering}
                                type="text"
                                label={`${labelNameCatering} ${index + 1}`}
                                validate={requiredMessage}
                              />

                              <FieldTextInput
                                id={`${name}.description`}
                                name={`${name}.description`}
                                className={css.descriptionCatering}
                                type="textarea"
                                label={labelDescriptionCatering}
                                validate={requiredMessage}
                              />

                              {!onChangePrice &&
                                <FieldCurrencyInput
                                  id={`${name}.cost`}
                                  name={`${name}.cost`}
                                  type="number"
                                  min={0}
                                  currencyConfig={config.currencyConfig}
                                  className={css.costCatering}
                                  placeholder=' '
                                  label={labelCostCatering}
                                  validate={priceValidators}
                                />
                              }

                              <button
                                type="button"
                                className={index === 0
                                  ? classNames(css.removeItemVisible, css.removeItem)
                                  : css.removeItem}
                                onClick={() => {
                                  setOnchangePrice(true);
                                  fields.remove(index);

                                  setTimeout(() => {
                                    setOnchangePrice(false);
                                  })
                                }
                                }
                              >
                                <span className={css.deleteMobile}>
                                  <FormattedMessage id="EditListingPoliciesForm.delete" />
                                </span>
                              </button>

                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                </FieldArray>

                {
                  onCheckInvalidFields(cateringPackages) && isClickAdd &&
                  <p className={css.error}>
                    <FormattedMessage id="EditListingRentalsAndUpgradesForm.alertAddItem" />
                  </p>
                }

                <Button
                  className={css.addingButton}
                  type="button"
                  onClick={() => {
                    setIsClickAdd(true);
                    const checkingToAdd = onCheckInvalidFields(cateringPackages);
                    if (!checkingToAdd) {
                      form.mutators.push('cateringPackages', {})
                    }
                  }}
                >
                  <FormattedMessage id="EditListingPoliciesForm.addCatering" />
                </Button>
              </>
            }

            <FieldDropdownCustom
              id="minBookHour"
              name="minBookHour"
              categories={minBookingHours}
              intl={intl}
              form={form}
              className={css.minBooking}
              classNameSelected={css.contentMinBooking}
              labelClass={css.labelMinBooking}
              label={labelMinBooking}
              validate={requiredMessage}
              placeholder={minBookingPlaceholder}
            />

            <FieldTextInput
              id="rules"
              name="rules"
              className={rules ? classNames(css.policy, css.policySuccess) : css.policy}
              type="textarea"
              label={rulesLabelMessage}
              placeholder={rulesPlaceholderMessage}
            />


            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form >
        );
      }}
    />
  )
};

EditListingPoliciesFormComponent.defaultProps = { fetchErrors: null };

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
