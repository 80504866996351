import React from 'react';
import css from './AboutPage.css';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import contactImg from '../../assets/roomChair.jpg';
import { renderSocialMediaLinks } from '../../components/Footer/Footer';

const SectionContact = (
  {
    intl
  }
) => {
  const socialLink = renderSocialMediaLinks(intl);
  return (
    <div id="contact" className={css.sectionWrapper}>
      <div className={css.sectionContact}>
        <div className={css.contactDetail}>
          <p className={css.contactLabel}>
            <FormattedMessage id={'AboutPage.contactTxt'} />
          </p>
          <p className={css.contactDetailBody}>
            <FormattedMessage id={'AboutPage.otherVenueEmail'} values={{ breakLine: <br/> }} />
          </p>
          <p className={css.contactLabel}>
            <FormattedMessage id={'AboutPage.stayConnectedTxt'} />
          </p>
          <p>
            {socialLink}
          </p>
        </div>
        <div className={css.contactImg}>
          <img src={contactImg} alt={intl.formatMessage({id: 'AboutPage.contactImg'})} />
        </div>
      </div>
    </div>
  )
};

SectionContact.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SectionContact);
