/*
 * Marketplace specific configuration.
 */

export const amenities = [
  {
    key: 'chairs_and_tables',
    label: 'Chairs and Tables Included',
  },
  {
    key: 'dinnerware',
    label: 'Dinnerware Included',
  },
  {
    key: 'tent',
    label: 'Tent Included',
  },
  {
    key: 'event_coordinator',
    label: 'Event Coordinator',
  },
  {
    key: 'kitchen',
    label: 'Kitchen Use',
  },
  {
    key: 'sound_system',
    label: 'Sound System Available',
  },
  {
    key: 'hot_tub',
    label: 'Hot Tub',
  },
  {
    key: 'swimming_pool',
    label: 'Swimming Pool',
  },
  {
    key: 'wifi',
    label: 'Wifi',
  },
  {
    key: 'bbq_grill',
    label: 'BBQ Grill',
  },
  {
    key: 'lighting',
    label: 'Lighting (for outdoor venues)',
  },
  {
    key: 'own_food_allowed',
    label: 'Own food allowed',
  },
  {
    key: 'farm_animals',
    label: 'Farm Animals',
  },
  {
    key: 'catering_packages',
    label: 'Catering Packages',
  },
  {
    key: 'bathrooms',
    label: 'Bathroom(s)',
  },
  {
    key: 'some_parking',
    label: 'Some Parking',
  },
  {
    key: 'plenty_of_parking',
    label: 'Plenty of Parking',
  },
  {
    key: 'garden_greenery',
    label: 'Garden/Greenery',
  },
  {
    key: 'large_deck',
    label: 'Large Deck',
  },
  {
    key: 'fountain',
    label: 'Fountain',
  },
  {
    key: 'view',
    label: 'View',
  },
  {
    key: 'amazing_backdrop',
    label: 'Amazing Backdrop',
  },
  {
    key: 'kids_play_area',
    label: 'Kids Play Area',
  },
  {
    key: 'barn',
    label: 'Barn',
  },
  {
    key: 'pond_or_lake',
    label: 'Pond or Lake',
  },
  {
    key: 'private_beach',
    label: 'Private Beach',
  },
];

export const categories = [
  { key: 'smoke', label: 'Smoke' },
  { key: 'electric', label: 'Electric' },
  { key: 'wood', label: 'Wood' },
  { key: 'other', label: 'Other' },
];

export const venueTypes = [
  { key: 'indoor', label: 'Indoor' },
  { key: 'outdoor', label: 'Outdoor' },
  { key: 'indoor_and_outdoor', label: 'Indoor and Outdoor' },
];

export const caterings = [
  { key: 'no_food', label: 'No food, please' },
  { key: 'bring_food', label: 'Bring your own food ' },
  { key: 'food_allowed', label: 'Catering optional; food allowed' },
  { key: 'catering_package', label: 'Catering package required' },
];

export const minBookingHours = [
  { key: 'no_minimum', label: 'No minimum' },
  { key: '2_hours', label: '2 hours' },
  { key: '3_hours', label: '3 hours' },
  { key: '4_hours', label: '4 hours' },
  { key: '5_hours', label: '5 hours' },
];

export const rentalItems = [
  { key: 'chairs', label: 'Chairs' },
  { key: 'tables', label: 'Tables' },
  { key: 'tent', label: 'Tent' },
  { key: 'dinnerware', label: 'Dinnerware' },
  { key: 'centerpieces', label: 'Centerpieces' },
  { key: 'linens', label: 'Linens' },
  { key: 'event_coordinator', label: 'Event Coordinator' },
  { key: 'other', label: 'Other' },
];

export const guestRange = [
  { key: '1,5', label: '1-5' },
  { key: '5,10', label: '5-10' },
  { key: '10,20', label: '10-20' },
  { key: '20,30', label: '20-30' },
  { key: '30,40', label: '30-40' },
  { key: '40,50', label: '40-50' },
  { key: '50,60', label: '50-60' },
  { key: '60,75', label: '60-75' },
  { key: '75,100', label: '75-100' },
  { key: '100,', label: '100+' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  id: 'dates',
  labelId: 'SearchPage.dateFilter',
  type: 'BookingDateRangeLengthFilter',
  group: 'primary',
  // Note: BookingDateRangeFilter is fixed filter,
  // you can't change "queryParamNames: ['dates'],"
  paramName: 'dates',//['dates', 'minDuration'],
  config: {
    // A global time zone to use in availability searches. As listings
    // can be in various time zones, we must decide what time zone we
    // use in search when looking for available listings within a
    // certain time interval.
    //
    // If you have all/most listings in a certain time zone, change this
    // config value to that.
    //
    // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    searchTimeZone: 'Etc/UTC',
    active: true
  },
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const CATERING_PACKAGES = 'cateringPackages';
export const CATERING_PACKAGE = 'catering_package';
export const OTHER = 'other';

export const BRING_FOOD = 'bring_food';
export const NO_FOOD = 'no_food';
export const FOOD_ALLOWED = 'food_allowed';

export const NO_MINIMUM = 'no_minimum';
export const FIVE_HOUR = '5_hours';

