import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { calculateQuantityFromHours, timestampToDate } from '../../util/hoursDates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, PrimaryButton, FieldTextInput, FieldCheckboxGroup, FieldCheckboxBooking, FieldCheckbox } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import { required, composeValidators, maxLengthForGuests, minLengthForGuests } from '../../util/validators';
import { avoidPressNegative } from '../../util/avoidPressNegative';
import { FieldArray } from 'react-final-form-arrays';
import { FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { NO_FOOD, BRING_FOOD, NO_MINIMUM, FOOD_ALLOWED } from '../../marketplace-custom-config';
import { isEmpty } from 'lodash';

import css from './BookingTimeForm.css';
const { Money } = sdkTypes;

const NUMBER_LENGTH_MIN = 1;

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: null,
      requiredCatering: false,
      flagCateringQuantity: false,
      isCheckingArrangement: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    const {
      guestCateringQuantity,
      cateringPackages = [],
    } = e || {};
    const { cateringType } = this.props.listing.attributes.publicData;
    const hasCateringPackages = cateringType !== NO_FOOD && cateringType !== BRING_FOOD;
    if (guestCateringQuantity && cateringPackages.length === 0 && hasCateringPackages) {
      this.setState({
        requiredCatering: true,
      });
      return;
    }

    this.props.onSubmit(e);
  }

  render() {
    const { rootClassName, className, price: unitPrice, onFocusDate, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    const { listing, intl } = this.props;
    const { rentalItems, maxGuest } = listing.attributes.publicData;
    const defaultService = new Array(rentalItems.length);

    const maxLengthMessage = intl.formatMessage(
      { id: 'BookingTimeForm.maxLengthGuest' },
      {
        maxLength: maxGuest,
      }
    );

    const maxLengthSupplyMessage = (maxLength) => {
      return intl.formatMessage(
        { id: 'BookingTimeForm.maxLengthSupply' },
        {
          maxLength
        }
      )
    };

    const maxLengthGuest = maxLengthForGuests(maxLengthMessage, maxGuest);

    const numberMinLengthMessage = intl.formatMessage(
      {
        id: 'BookingTimeForm.numberTooShort',
      },
      {
        minLength: NUMBER_LENGTH_MIN,
      }
    );

    const minLengthRequest = minLengthForGuests(numberMinLengthMessage, NUMBER_LENGTH_MIN);

    return (
      <FinalForm
        {...rest}
        onSubmit={this.handleFormSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={
          this.state.initialValues
            ? this.state.initialValues
            : {
              supplies: defaultService,
            }
        }
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            listing,
          } = fieldRenderProps;
          const { publicData } = listing.attributes;
          const {
            cateringPackages = [],
            cateringType, rentalItems = [],
            minBookHour = '',
          } = publicData;
          const numberMinBookHour = minBookHour[0];

          const {
            foodAllowedOption,
            cateringPackages: cateringPackagesCurrent = [],
          } = values;

          if (this.state.isCheckingArrangement) {
            cateringPackagesCurrent.length = 0;
            delete values.guestCateringQuantity;
          } else {
            delete values.foodAllowedOption;
          }

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;
          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.

          const bookingData =
            startDate && endDate
              ? {
                unitType,
                startDate,
                endDate,
                // Calculate the quantity as hours between the booking start and booking end
                quantity: calculateQuantityFromHours(startDate, endDate),
                timeZone,
              }
              : null;

          const submitButtonClassesRent = classNames(submitButtonWrapperClassName || css.submitButtonWrapper);
          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const nameEventRequiredMessage = intl.formatMessage({
            id: 'BookingTimeForm.nameEventRequired',
          });

          const numberGuestRequiredMessage = intl.formatMessage({
            id: 'BookingTimeForm.numberGuestRequired',
          });

          const foodAllowedOptionLabel = intl.formatMessage({
            id: 'BookingTimeForm.foodAllowedOption',
          });

          const breakLine = <br />;

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <FormSpy
                onChange={formState => {
                  const { values } = formState;
                  const {
                    cateringPackages = [],
                    guestCateringQuantity,
                    guestEventQuantity,
                  } = values || {};
                  if (cateringPackages.length > 0 && guestCateringQuantity) {
                    this.setState({
                      requiredCatering: false,
                    });
                  }

                  if (cateringPackages.length > 0 && guestEventQuantity
                    && !guestCateringQuantity && !this.state.flagCateringQuantity) {
                    values.guestCateringQuantity = guestEventQuantity;
                    this.setState({
                      flagCateringQuantity: true,
                    });
                  }

                  this.setState({
                    initialValues: values,
                  });
                }}
              />
              <p className={css.titleDate}>
                <FormattedMessage id="BookingTimeForm.titleDate" />
              </p>
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  onFocusDate={onFocusDate}
                />
              ) : null}

              {startDate && endDate
                && minBookHour !== NO_MINIMUM
                && bookingData
                && bookingData.quantity < numberMinBookHour
                &&
                <p className={css.requiredCatering}>
                  <FormattedMessage id="BookingTimeForm.requiredDateTime" values={{ hour: parseInt(numberMinBookHour) }} />
                </p>
              }

              <FieldTextInput
                id="nameEvent"
                name="nameEvent"
                className={css.nameEvent}
                type="text"
                label={intl.formatMessage({ id: 'BookingTimeForm.titleNameEvent' })}
                validate={composeValidators(required(nameEventRequiredMessage))}
              />

              <FieldTextInput
                id="guestEvent"
                name="guestEventQuantity"
                className={css.guestEvent}
                type="number"
                min={0}
                step={1}
                label={intl.formatMessage({ id: 'BookingTimeForm.numberGuestEvent' })}
                onKeyDown={avoidPressNegative}
                validate={composeValidators(required(numberGuestRequiredMessage), minLengthRequest, maxLengthGuest)}
              />

              <div className={css.containerSection}>
                <h3 className={css.titleCatering}>
                  <FormattedMessage id="BookingTimeForm.titleCatering" />
                </h3>

                {cateringType === NO_FOOD
                  ? <p className={css.noCatering}>
                    <FormattedMessage id="BookingTimeForm.noFood" />
                  </p>
                  : cateringType === BRING_FOOD
                    ? <p className={css.noCatering}>
                      <FormattedMessage id="BookingTimeForm.bringFood" values={{ breakLine }} />
                    </p>
                    : <p className={css.subTitleCatering}>
                      <FormattedMessage id="BookingTimeForm.subTitleCatering" />
                    </p>
                }

                {cateringPackages.map((item, index) => {
                  const { name, cost } = item;
                  const formattedPrice = formatMoney(
                    intl,
                    new Money(cost, config.currency)
                  );
                  return (
                    <div key={index} className={css.catering}>
                      <FieldCheckbox
                        id={`cateringPackages.${index}`}
                        name="cateringPackages"
                        label={name}
                        value={JSON.stringify(item)}
                        textClassName={css.cateringLabel}
                        className={css.leftCatering}
                        onClick={() => {
                          this.setState({ isCheckingArrangement: false })
                        }}
                      />
                      <span className={css.price}>
                        {formattedPrice}
                        <span className={css.perGuest}>
                          <FormattedMessage id="BookingTimeForm.priceCatering" />
                        </span>
                      </span>
                    </div>
                  );
                })}

                {cateringType === FOOD_ALLOWED &&
                  <FieldCheckbox
                    id="foodAllowedOption"
                    name="foodAllowedOption"
                    label={foodAllowedOptionLabel}
                    textClassName={css.cateringLabel}
                    className={css.leftCatering}
                    onClick={() => {
                      this.setState({
                        isCheckingArrangement: !this.state.isCheckingArrangement
                      })
                    }}
                  />
                }

                {(cateringType !== NO_FOOD && cateringType !== BRING_FOOD) &&
                  <div className={css.wrapCatering}>
                    <span className={css.titleGuestCatering}>
                      <FormattedMessage id="BookingTimeForm.titleGuestCatering" />
                    </span>
                    <FieldTextInput
                      id="guestCatering"
                      name="guestCateringQuantity"
                      className={foodAllowedOption
                        ? classNames(css.guestCatering, css.hasFoodAllowed)
                        : css.guestCatering}
                      type="number"
                      min={0}
                      step={1}
                      validate={foodAllowedOption
                        ? null
                        : composeValidators(required(numberGuestRequiredMessage), minLengthRequest)}
                      onKeyDown={avoidPressNegative}
                    />
                  </div>
                }
                {this.state.requiredCatering &&
                  <p className={css.requiredCatering}>
                    <FormattedMessage id="BookingTimeForm.requiredCatering" />
                  </p>
                }
              </div>

              <div className={css.containerSection}>
                <h3 className={css.titleSupplies}>
                  <FormattedMessage id="BookingTimeForm.titleSupplies" />
                </h3>

                {rentalItems.length > 0
                  ? <>
                    <p className={css.subTitleSupplies}>
                      <FormattedMessage id="BookingTimeForm.subTitleSubpplies" />
                    </p>

                    {rentalItems &&
                      <FieldArray name="supplies">
                        {({ fields }) => {
                          return (
                            <div className={css.wrapServices}>
                              {fields.map((name, index) => {
                                const { rentalItem, rentalItemsLabel, renameRental, maxQuantityRental, costPerRentalItem } = rentalItems[index];
                                return !isEmpty(rentalItems[index]) && (
                                  <div className={css.wrapFieldItem}>

                                    <span className={css.nameSupply}>
                                      {renameRental || rentalItemsLabel}
                                    </span>

                                    <span className={css.priceSupply}>
                                      <FormattedMessage
                                        id="BookingTimeForm.priceSupply"
                                        values={{
                                          price: formatMoney(intl, new Money(costPerRentalItem, config.currency)),
                                          quantity: maxQuantityRental
                                        }}
                                      />
                                    </span>

                                    <FieldTextInput
                                      name={name}
                                      className={css.itemSupply}
                                      type="number"
                                      min={0}
                                      step={1}
                                      onKeyDown={avoidPressNegative}
                                      validate={composeValidators(
                                        maxLengthForGuests(maxLengthSupplyMessage(maxQuantityRental), maxQuantityRental),
                                        minLengthRequest)}
                                      hasSpecialValidation={true}
                                      classNameValidation={css.validationSupply}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }}
                      </FieldArray>
                    }
                  </>
                  : <p className={css.noCatering}>
                    <FormattedMessage id="BookingTimeForm.noSupply" values={{ breakLine }} />
                  </p>
                }

              </div>

              <div className={submitButtonClassesRent}>
                <PrimaryButton rootClassName={css.submitBookingForm} type="submit">
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;




