import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: April 5, 2021</p>

      <p>
        Please read these Terms of Service (“Terms”) carefully as they
        contain important information about your legal rights, remedies
        and obligations. By accessing or using the OtherVenue Platform,
        you agree to comply with and be bound by these Terms.
      </p>

      <p>
        <strong>Please note:</strong> These Terms contain an arbitration agreement
        and class action waiver that applies to all claims brought against OtherVenue
        in the United States. By accepting these Terms, you agree to be bound
        by this arbitration clause and class action waiver. Please read it
        carefully.
      </p>

      <p>
        Thank you for using OtherVenue.
      </p>

      <p>
        <strong>General Scope of Terms</strong><br />
        These Terms are a legally binding agreement ("<strong>Agreement</strong>") between you and OtherVenue
        (as defined below) governing your access to and use of the OtherVenue website, including
        any subdomains thereof, and any other websites through which OtherVenue makes its
        services available (collectively, "<strong>Site</strong>"), our mobile, tablet and other smart device
        applications, and application program interfaces (collectively, "<strong>Application</strong>") and
        all associated services (collectively, "<strong>OtherVenue Services</strong>"). The Site, Application
        and OtherVenue Services together are hereinafter collectively referred to as the
        “<strong>OtherVenue Platform</strong>”. When these Terms mention “<strong>OtherVenue</strong>,”
        “<strong>we</strong>,” “<strong>us</strong>,” or <strong>our</strong>,”
        it refers to the OtherVenue company you are contracting with. Your contracting entity
        will generally be determined based on your country of residence or establishment. If
        your country of residence or establishment is the United States, you are contracting
        with OtherVenue LLC.
      </p>

      <p>
        Our collection and use of personal information in connection with your access to and
        use of the OtherVenue Platform is described in our <strong>Privacy Policy</strong>.
      </p>

      <p>
        Owners alone are responsible for identifying, understanding, and complying with all
        laws, rules and regulations that apply to their Listings. For example, in some cities,
        Owners may be required to register, get a permit or obtain a license before offering
        certain services (such as preparing food or serving alcohol). Certain types of Services
        may be prohibited altogether. Penalties may include fines or other enforcement. Owners
        are solely responsible for identifying and obtaining any required licenses, permits,
        or registrations for any Services they offer. If you have questions about how local
        laws apply to your Listing(s) on OtherVenue, you should always seek legal guidance.
      </p>

      <p>
        The OtherVenue Platform offers an online tool that enables users (“<strong>Members</strong>”)
        to publish, offer, search for, and book services. Members who publish and offer services are
        “<strong>Listing Owners</strong>” (sometimes referred to as "<strong>Owners</strong>” or
        “<strong>Hosts</strong>”) and Members who search for, book, or use services are
        “<strong>Guests.</strong>” Listing Owners offer event space (“<strong>Venue</strong>”)
        and a variety of other supplies or services (collectively "<strong>Listing</strong>" or
        “<strong>Services</strong>”).
      </p>

      <p>
        You must register an account to access and use many features of the OtherVenue Platform
        and keep your account information accurate. As the provider of the OtherVenue Platform,
        OtherVenue does not own, control, offer or manage, deliver or supply any Listings.
        OtherVenue is not a party to the contracts concluded directly between Owners and Guests,
        nor is OtherVenue a real estate broker or insurer. OtherVenue is not acting as an agent
        in any capacity for any Member, nor is OtherVenue an organizer or event planner. When
        Members make or accept a booking, they are entering into a contract directly with each
        other.
      </p>

      <p>
        While we may help facilitate the resolution of disputes, OtherVenue has no control over
        and does not guarantee (i) the existence, quality, safety, suitability, or legality of
        any Listings (ii) the truth or accuracy of any Listing descriptions, Ratings, Reviews,
        or other Member Content (as defined below), or (iii) the performance or conduct of any
        Member or third party. OtherVenue does not endorse any Member, Listing or Services.
        Any references to a Member being "verified" (or similar language) only indicate that
        the Member has completed a relevant verification or identification process and nothing
        else. Any such description is not an endorsement, certification or guarantee by
        OtherVenue about any Member, including of the Member's identity or background or whether
        the Member is trustworthy, safe or suitable. You should always exercise due diligence and
        care when booking a Listing, using other Services, accepting a booking request from a Guest,
        or communicating and interacting with other Members, whether online or in person. Verified
        Images (as defined below) are intended only to indicate a photographic representation of a
        Listing at the time the photograph was taken, and are therefore not an endorsement by OtherVenue
        of any Owner or Listing.
      </p>

      <p>
        If you choose to use the OtherVenue Platform as a Listing Owner, your relationship with
        OtherVenue is limited to being an independent, third-party contractor, and not an employee,
        agent, joint venturer or partner of OtherVenue for any reason, and you act exclusively on
        your own behalf and for your own benefit, and not on behalf, or for the benefit, of OtherVenue.
        OtherVenue does not, and shall not be deemed to, direct or control you generally or in your
        performance under these Terms specifically, including in connection with your provision of
        the Services. You acknowledge and agree that you have complete discretion whether to list
        Services or otherwise engage in other business or activities.
      </p>

      <p>
        To promote the OtherVenue Platform and to increase the exposure of Listings to potential Guests,
        Listings and other Member Content may be displayed on other websites, in applications, within
        emails, and in online and offline advertisements. To assist Members who speak different languages,
        Listings and other Member Content may be translated, in whole or in part, into other languages.
        OtherVenue cannot guarantee the accuracy or quality of such translations and Members are responsible
        for reviewing and verifying the accuracy of such translations. The OtherVenue Platform may contain t
        ranslations powered by Google. Google disclaims all warranties related to the translations, express
        or implied, including any warranties of accuracy, reliability, and any implied warranties for
        merchantability, fitness for a particular purpose and non-infringement.
      </p>

      <p>
        The OtherVenue Platform may contain links to third-party websites or resources (“<strong>Third-Party
        Services</strong>”). Such Third-Party Services may be subject to different terms and conditions
        and privacy practices. OtherVenue is not responsible or liable for the availability or accuracy of such
        Third-Party Services, or the content, products, or services available from such Third-Party Services.
        Links to such Third-Party Services are not an endorsement by OtherVenue of such Third-Party Services.
      </p>

      <p>
        Due to the nature of the Internet, OtherVenue cannot guarantee the continuous and uninterrupted
        availability and accessibility of the OtherVenue Platform. OtherVenue may restrict the availability
        of the OtherVenue Platform or certain areas or features thereof, if this is necessary in view of
        capacity limits, the security or integrity of our servers, or to carry out maintenance measures
        that ensure the proper or improved functioning of the OtherVenue Platform. OtherVenue may improve,
        enhance and modify the OtherVenue Platform and introduce new OtherVenue Services from time to time.
      </p>


      <h2>Guest Terms</h2>

      <p><strong>1. Searching and Booking on OtherVenue</strong></p>

      <p>
        <strong>1.1 Searching.</strong>You can search for Listings by using location as the
        search criteria. You can also use filters to refine your search results.
        Search results are based on their relevance to your search and other
        criteria. Relevance considers factors like price and availability, Owner
        requirements (e.g. minimum hours). Contact our support team
        support@othervenue.com if you need help with search features.
      </p>

      <p>
        <strong>1.2 Booking.</strong>When you book a Listing, you are agreeing
        to pay all charges for your booking including the Listing price,
        applicable fees like OtherVenue's service fee, offline fees, taxes,
        and any other items identified during checkout (collectively,
        “<strong>Total Price</strong>”). You are also agreeing that a
        third-party payment processor may charge and collect
        any security deposit identified during checkout. When you receive the
        booking confirmation, a contract for the Listing (sometimes called a
        reservation in these Terms) is formed directly between you and the
        Listing Owner. The cancellation policy and any other rules, standards,
        policies, or requirements identified in the Listing or during checkout
        form part of your contract with the Host. Be aware that some Hosts
        work with a co-host or as part of a team to provide their Listing.
      </p>

      <p>
        <strong>1.3 Venue Reservation.</strong> A Venue reservation is a
        limited license to enter, occupy, and use the Venue according to
        the terms of your booking. The Owner retains the right to re-enter
        the Venue during your reservation, to the extent: (i) it is reasonably
        necessary, (ii) permitted by your contract with the Owner, and (iii)
        consistent with applicable law. If you stay past checkout, the Owner
        has the right to make you leave in a manner consistent with applicable
        law, including by imposing reasonable overstay penalties. You may not
        exceed the maximum number of allowed Guests.
      </p>

      <p>
        <strong>2. Cancellations, Refunds and Booking Modifications</strong>
      </p>

      <p>
        <strong> 2.1 Cancellations and Refunds.</strong> In general, if as a
        Guest you cancel a reservation, the amount refunded to you is determined
        by the cancellation policy that applies to that reservation. OtherVenue
        allows you as a Guest to cancel your reservation and receive a full refund,
        as long as the cancellation occurs more than 72 hours before the reservation's
        start time. Hosts may also specify other cancellation requirements, which
        shall be clearly described on their Listing page.
      </p>

      <p>
        <strong>2.2 Booking Modifications.</strong> Guests and Owners are
       responsible for any booking modifications they agree to make via the
       OtherVenue Platform or direct OtherVenue customer support to make on
       their behalf ("Booking Modifications"), and agree to pay any additional
       amounts, fees, or taxes associated with any Booking Modification.
      </p>

      <p><strong>3.0 Your Responsibilities and Assumption of Risk</strong></p>

      <p>
        <strong>3.1 Your Responsibilities.</strong>
        You are responsible and liable for your own acts and omissions and are also responsible for the acts and omissions of anyone you invite to join or provide access to any Venue or Service. For example, this means: (i) you are responsible for leaving a Venue (and related rented or personal property) in the condition it was in when you arrived, and (ii) you must act with integrity, treat others with respect, and comply with applicable laws at all times. If you will have any event guests who are minors or if one of your minor guests uses a Service, you must be legally authorized to act on behalf of the minor and you are solely responsible for the supervision of that minor.
      </p>

      <p>
        <strong>
          3.2 Your Assumption of Risk. You acknowledge that many activities carry
          inherent risks and agree that, to the maximum extent permitted by
          applicable law, you assume the entire risk arising out of your access
          to and use of the OtherVenue Platform, including your stay at or use
          of the Venue, use of any Services, or any other interaction you have
          with other Members whether in person or online.
        </strong>
      </p>


      <h2>Listing Owner Terms</h2>

      <p><strong>4. Hosting on OtherVenue.</strong></p>

      <p>
        <strong>4.1 Host. </strong>
        As a Listing Owner, OtherVenue allows you to list your space and use
        the OtherVenue Platform to connect with our community of Guests,
        accept bookings, and get paid through our secure payment processing.
        It’s easy to create a Listing, and you are in control of your
        Listing's price, availability, and rules for each Listing.
      </p>

      <p>
        <strong>4.2 Contracting with Guests. </strong>
        When you accept a booking request, or receive a booking confirmation
        through the OtherVenue Platform, you are entering into a contract
        directly with the Guest, and are responsible for delivering your
        Service under the terms and at the price specified in your Listing.
        You are also agreeing to pay applicable fees like OtherVenue’s
        service fee (and any applicable taxes) for each booking. A third-party
        payment processor will deduct amounts you owe from your
        payout unless we and you agree to a different method. Any terms,
        policies or conditions that you include in any supplemental contract
        with Guests must: (i) be consistent with these Terms, our Policies,
        and the information provided in your Listing, and (ii) be prominently
        disclosed in your Listing description.
      </p>


      <p>
        <strong>4.3 Independence of Hosts.</strong>
        Your relationship with OtherVenue is that of an independent individual
        or entity and not an employee, agent, joint venturer, or partner of
        OtherVenue. OtherVenue does not direct or control your Venue or Service,
        and you agree that you have complete discretion whether and when to
        provide Services, and at what price and on what terms to offer them.
      </p>

      <p><strong>5. Managing Your Listing.</strong></p>

      <p>
        <strong>5.1 Creating and Managing Your Listing.</strong>
        The OtherVenue Platform provides tools that make it easy for you
        to set up and manage a Listing. Your Listing must include complete
        and accurate information about your Services, your price, other charges
        like cleaning fees, security deposits, offline fees, and any rules or
        requirements that apply to your Guests or Listing. You are responsible
        for keeping your Listing information (including calendar availability)
        and content (like photos) up-to-date and accurate at all times. We
        recommend that you obtain appropriate insurance for your Services
        and suggest you carefully review policy terms and conditions including
        coverage details and exclusions. You may only maintain one Listing per
        venue space, but may have multiple Listings for a single property if it
        has multiple bookable spaces.
      </p>


      <p>
        <strong>5.2 Know Your Legal Obligations. </strong>
        You are responsible for understanding and complying with any laws, rules,
        regulations, and contracts with third parties that apply to your Listing
        or Services. For example:  Some cities have zoning or other laws that
        restrict gatherings. Some jurisdictions require Hosts to register,
        get a permit, or obtain a license before providing certain Services
        (such as short-term rentals, preparing food, or serving alcohol
        for sale). In some places, the Services you want to offer may be
        prohibited altogether. Check your local rules to learn what rules
        apply to the Services you plan to offer. Information we provide
        regarding legal requirements is for informational purposes only
        and you should independently confirm your obligations. You are
        responsible for handling and using personal data of Guests and
        others in compliance with applicable privacy laws and these Terms.
        If you have questions about how local laws apply you should always
        seek legal advice.
      </p>

      <p>
        <strong>5.3 Search Ranking.</strong>
        The ranking of Listings in search results on the OtherVenue Platform
        depends on a variety of factors, which may include these parameters:
      </p>

      <p>
        • Guest search parameters (e.g. number of Guests, date of event,
        catering options, price range), Listing characteristics (e.g. price,
        calendar availability, number and quality of images, Reviews, Owner
        status, age of the Listing, average Guest popularity),
      </p>

      <p>
        • Guest booking experience (e.g. customer service and cancellation
        history of the Host, ease of booking),
      </p>

      <p>
        • Host requirements (e.g. maximum guests, catering), and
      </p>

      <p>
        • Guest preferences (e.g. previous bookings, saved Listings,
        location from where the Guest is searching).
      </p>


      <p>
        Search results may appear differently on our mobile application
        than they appear on our website. OtherVenue may allow Owners
        to promote their Listings in search or elsewhere on the OtherVenue
        Platform by paying an additional fee.
      </p>

      <p>
        <strong>5.4 Your Responsibilities.</strong>
        You are responsible and liable for your own acts and omissions
        and are also responsible for the acts and omissions of anyone
        you allow to participate in providing your Services. You are
        responsible for setting your price and establishing rules and
        requirements for your Listing. You must describe any and all
        fees and charges in your Listing description and may not collect
        any additional fees or charges outside the OtherVenue Platform.
        Do not encourage Guests to create third-party accounts, submit
        reviews, provide their contact information, contact you to in
        order to facilitate payment offline, or take other actions
        outside the OtherVenue Platform, which is in violation of
        these Terms.
      </p>

      <p>
        <strong>5.5 Hosting as a Team or Organization.</strong>
        If you work with a co-owner or host as part of a team, business,
        or other organization, the entity and each individual who
        participates in providing Services is responsible and liable
        as a Host under these Terms. If you accept terms or enter into
        contracts, you represent and warrant that you are authorized to
        enter into contracts for and bind your team, business or other
        organization, and that each entity you use is in good standing
        under the laws of the place where it is established. If you perform
        other functions, you represent and warrant that you are authorized
        to perform those functions. If you instruct OtherVenue to transfer a
        portion of your payout to a co-owner or other Hosts, or to send
        payments to someone else, you must be authorized to do so, and are
        responsible and liable for the payment amounts and accuracy of any
        payout information you provide.
      </p>

      <p>
        <strong>5.6 Your Assumption of Risk.</strong>
        You acknowledge that hosting carries inherent risks and agree that
        you assume the entire risk arising out of your access to and use
        of the OtherVenue Platform, offering  Services, or any interaction
        you have with other Members whether in person or online. You agree
        that you have had the opportunity to investigate the OtherVenue
        Platform and any laws, rules, regulations, or obligations that
        may be applicable to your Listings or Services and that you are
        not relying upon any statement of law made by OtherVenue.
      </p>


      <p><strong>6. Cancellations and Booking Modifications.</strong></p>

      <p>
        <strong>6.1 Cancellations.</strong>
        In general, OtherVenue allows Guests to cancel their reservation
        and receive a full refund, as long as the cancellation occurs more
        than 72 hours before the reservation begins. As a host, you may
        specify other requirements for Guests, as long as they are clearly
        and prominently disclosed and described on your Listing page, and
        managed by you. As a Host, you should not cancel on a Guest without
        a valid reason. Extenuating circumstances that qualify as valid
        reasons are as follows:
      </p>

      <p>
        <strong>• Death or unexpected serious illness of Host, co-owner
        or guest
        </strong>
        occurring after the reservation was made. Valid documentation,
        including a death certificate, obituary, news article naming
        the deceased, or police report in the event of a death, or a
        statement from a physician confirming that the person can't host
        or carry on with their event due to serious illness, should be
        provided within 14 days of cancellation.
      </p>

      <p>
        <strong>• Government-mandated obligations,</strong>
        including jury duty, restrictions on gathering, court appearances,
        and military deployment. Within 14 days of cancellation, you'll be
        asked to provide a copy of the official notice dated after the
        reservation was booked, including the name of the person
        fulfilling the obligation.
      </p>

      <p>
        <strong>• Unforeseen property damage, maintenance, and amenity issues</strong>
        to the OtherVenue listing that makes it unsafe to host guests, or that
        prevents guests from accessing basic amenities like electricity.
        This doesn’t include planned renovations. You’ll be asked to
        provide all of the following documents: 1)Proof that the issue
        is being fixed; 2) An estimate of when it will be fixed; 3)
        An invoice for the repairs being done and 4) Photos of the
        damage.
      </p>

      <p>
        If you cancel on a Guest without such a valid reason, we may
        impose a cancellation fee and other consequences. If a Guest
        cancels for any valid reason, the amount you are paid will
        be reduced by the amount we refund or otherwise provide to
        the Guest, and by any other reasonable costs we incur as a
        result of the cancellation. If a Guest receives a refund
        after you have already been paid, or the amount of the refund
        and other costs incurred by OtherVenue exceeds your payout,
        OtherVenue (via our Third-Party payment processor) may recover
        that amount from you, including by offsetting the refund against
        your future payouts. You agree that these Terms preempt the
        cancellation policy you set in situations where they allow for
        the cancellation of a reservation and/or the issuance of refunds
        to Guests. If we reasonably expect to provide a refund to a Guest
        under one of these policies, we may delay release of any payout
        for that reservation until a refund decision is made.
      </p>


      <p>
        <strong>6.2 Booking Modifications.</strong>
        Owners and Guests are responsible for any Booking Modifications
        they agree to make via the OtherVenue Platform or direct OtherVenue
        customer service to make on their behalf, and agree to pay any
        additional amounts, fees or taxes associated with a Booking Modification.
      </p>

      <p><strong>7. Taxes.</strong></p>

      <p>
        <strong>7.1 Host Taxes.</strong>
        As a Listing Owner, you are responsible for determining and fulfilling
        your obligations under applicable laws to report, collect, remit, or
        include in your price any applicable VAT or other indirect taxes,
        occupancy taxes, tourist, income, or other taxes ("Taxes").
      </p>

      <p>
        <strong>
          7.2 Collection and Remittance by OtherVenue.
        </strong>
        In jurisdictions where OtherVenue facilitates the collection and/or
        remittance of Taxes on behalf of Hosts, you instruct and authorize
        OtherVenue to collect Taxes on your behalf, and/or to remit such
        Taxes to the relevant Tax authority. Any Taxes that are collected
        and/or remitted by OtherVenue are identified to Members on their
        transaction records, as applicable. OtherVenue may seek additional
        amounts from Members (including by deducting such amounts from future
        payouts) in the event that the Taxes collected and/or remitted are
        insufficient to fully discharge that Members’ tax obligations, and
        you agree that your sole remedy for Taxes collected by OtherVenue
        is a refund from the applicable Tax authority. You acknowledge and
        agree that we retain the right, with prior notice to affected Members,
        to cease the collection and remittance of Taxes in any jurisdiction for
        any reason.
      </p>

      <p>
        <strong>7.3 Tax Information.</strong>
        In certain jurisdictions, Tax regulations may require that we collect
        and/or report Tax information about you, or withhold Taxes from payouts
        to you, or both. If you fail to provide us with documentation that we
        determine to be sufficient to support any such obligation to withhold
        Taxes from payouts to you, we may withhold payouts up to the amount as
        required by law, until sufficient documentation is provided. You agree
        that OtherVenue may issue on your behalf invoices or similar documentation
        for any required venue, rental or other Taxes for your Services to facilitate
        accurate tax reporting by you, our Guests, and/or their organizations.
      </p>


      <h2>General Terms</h2>

      <p><strong>8. Reviews.</strong></p>

      <p>
        After each Booking, Guests and Listing Owners will have an opportunity
        to review each other. Your Review must be accurate, useful, and
        informative and may not contain any discriminatory, offensive,
        defamatory, or other language that violates our Content guidelines
        as described in Section 9 of the Terms. Reviews are not verified by
        OtherVenue for accuracy and may be incorrect or misleading. Reviews
        should be unbiased. Entities or individuals who own or are affiliated
        with a Listing should not provide a review for that Listing. Likewise,
        anyone who offers competing Services are not allowed to post reviews
        of their direct competitor. Reviews should be relevant to the OtherVenue
        community. Venue owners and guests will be reading your reviews to learn
        more about the Listing, so reviews should focus on your experience during
        your booking and any relevant interactions with other Members.
      </p>

      <p><strong>9. Content.</strong></p>

      <p>
        The OtherVenue Platform allows you to provide information, descriptions,
        text, photos, and other content (collectively, “Content”). By providing
        Content, in whatever form and through whatever means, you grant OtherVenue
        a non-exclusive, worldwide, royalty-free, irrevocable, perpetual, sub-licensable
        and transferable license to copy, modify, prepare derivative works of, distribute,
        publish and otherwise exploit, that Content, without limitation. If Content
        includes personal information, our Privacy Policy describes how we use that
        personal information. Where OtherVenue pays for the creation of Content or
        facilitates its creation, OtherVenue may own that Content, in which case
        supplemental terms or disclosures will say that. You are solely responsible
        for all Content that you provide and warrant that you either own it or are
        authorized to grant OtherVenue the rights described in these Terms. You are
        responsible and liable if any of your Content violates or infringes the intellectual
        property or privacy rights of any third party. Content must comply with our Content
        Policy and Nondiscrimination Policy, which prohibit, among other things, discriminatory,
        obscene, harassing, deceptive, violent, and illegal content. You agree that OtherVenue
        may make available services or automated tools to translate Content and that your
        Content may be translated using such services or tools. OtherVenue does not guarantee
        the accuracy or quality of translations and Members are responsible for confirming
        the accuracy of such translations.
      </p>

      <p><strong>10. Fees.</strong></p>

      <p>
        OtherVenue may charge fees (and applicable Taxes) to Listing Owners and Guests
        for use of the OtherVenue Platform. Guests are charged a 6% service fee at the
        time of booking. A service fee of 11% is deducted from Owners' payouts. Except
        as otherwise provided on the OtherVenue Platform, service fees are non-refundable.
        OtherVenue reserves the right to change the service fees at any time, and will
        provide Members notice of any fee changes before they become effective. Fee changes
        will not affect bookings made prior to the effective date of the fee change. If you
        disagree with a fee change you may terminate this agreement at any time pursuant
        to Section 12.2.
      </p>

      <p><strong>11. OtherVenue Platform Rules.</strong></p>

      <p>
        <strong>11.1 Rules.</strong>
         You must follow these rules and must not help or induce others to break or
         circumvent these rules.
      </p>


      <div className={css.wrapNoMargin}>
        <p>• Read and follow our Terms and standards. </p>
        <p>• Be polite, treat others respectfully, and act with integrity at all times.</p>
        <p>• Do not lie, misrepresent something or someone, or pretend to be someone else. </p>
        <p>• Do not discriminate against or harass others. </p>
        <p>• Do not scrape, hack, reverse engineer, compromise or impair the OtherVenue Platform. </p>
        <p>
          • Do not use bots, crawlers, scrapers, or other automated means to access or collect data
          or other content from or otherwise interact with the OtherVenue Platform.
        </p>
        <p>
          • Do not hack, avoid, remove, impair, or otherwise attempt to circumvent any
          security or technological measure used to protect the OtherVenue Platform or Content.
        </p>
        <p>
          • Do not decipher, decompile, disassemble, or reverse engineer any of the software
          or hardware used to provide the OtherVenue Platform.
        </p>
        <p>
          • Do not take any action that could damage or adversely affect the performance or
          proper functioning of the OtherVenue Platform.
        </p>
        <p>
          • Only use the OtherVenue Platform as authorized by these Terms or another agreement with us
          You may only use another Member’s personal information as necessary to facilitate
          a transaction using the OtherVenue Platform as authorized by these Terms.
        </p>
        <p>
          • Do not use the OtherVenue Platform, our messaging tools, or Members’ personal
          information to send commercial messages without the recipient’s express consent.
        </p>
        <p>
          • You may use Content made available through the OtherVenue Platform solely as necessary
          to enable your use of the OtherVenue Platform as a Guest or Host.
        </p>
        <p>
          • Do not use Content unless you have permission from the Content owner or the
          use is authorized by us in these Terms or another agreement you have with us.
        </p>
        <p>
          • Do not request, make, or accept a booking or any payment outside of the OtherVenue
          Platform to avoid paying fees, taxes or for any other reason.
        </p>
        <p>
          • Do not require or encourage Guests to open an account, leave a review, complete
          a survey, or otherwise interact, with a third party website, application or service
          before, during or after a reservation, unless authorized by OtherVenue.
        </p>
        <p>
          • Do not engage in any practices that are intended to manipulate our search algorithm.
          </p>
        <p>
          • Do not book Services unless you are actually using the Services.
          </p>
        <p>
          • Do not use, copy, display, mirror or frame the OtherVenue Platform, any Content,
          any OtherVenue branding, or any page layout or design without our consent.
         </p>
        <p>
          • Understand and follow the laws that apply to you, including export, privacy,
          and data protection laws. Do not use the OtherVenue Platform to offer Services
          that violate the agreements or laws that apply to you. If you provide us with
          another person’s personal information, you: (i) must be authorized to do so (ii)
          must do so in compliance with applicable law, and (iii) authorize OtherVenue to
          process that information under our Privacy Policy.
        </p>
        <p>
          • Do not organize or facilitate any unauthorized parties or activities. You are
          responsible and liable for any activity or event during your reservation that
          violates our rules, as incorporated by reference herein.
        </p>
        <p>
          • Do not use the name, branding, trademarks or logo of OtherVenue or others without
          permission.
        </p>
        <p>
          • Do not use or register any social media handle, domain name, trademark, trade name,
          logo, branding or other source identifier that may be confused with OtherVenue branding.
        </p>
        <p>
          • Do not offer or solicit, participate in or facilitate human trafficking or prostitution.
        </p>
      </div >


      <p>
        <strong>11.2 Reporting Violations.</strong>
        If you are concerned that a Listing, Content or Member has violated our
        Standards, you should report the issue to OtherVenue.If you believe that
        a Listing, Content or Member poses an imminent danger of harm to a property
        or person, you must contact local authorities immediately before contacting
        OtherVenue.If you've reported an issue to the local authorities, OtherVenue
        may request a copy of that report. You agree that OtherVenue is not obligated
        to take any  action in response to any report, except as required by applicable
        law.
      </p>

      <p>
        <strong>11.3 Copyright Notifications.</strong>
        If you believe that any Content on the OtherVenue Platform infringes
        copyrights, please notify us immediately.
      </p>


      <p><strong>12. Termination, Suspension and other Measures.</strong></p>

      <p>
        <strong>
          12.1 Term.
        </strong>
        The agreement reflected by these Terms is between you and OtherVenue
        and is effective when you access the OtherVenue Platform and shall
        remain in effect until either you or OtherVenue terminates the
        agreement in accordance with these Terms.
      </p>

      <p>
        <strong>12.2 Termination.</strong>
        You may terminate this agreement at any time by emailing us to request
        termination, or by deleting your account.OtherVenue may terminate this
        agreement and your account for any reason by providing you 30 days’
        notice via email or using any other contact information you've provided
        for your account. If you breach these Terms, you violate our Standards,
        you violate applicable laws, or we reasonably believe termination is
        necessary to protect OtherVenue, its Members, or third parties, we may
        terminate this agreement immediately and without notice and stop providing
        access to the OtherVenue Platform. If your account has been inactive for
        two years or more, we may terminate your account without  providing notice.
      </p>

      <p>
        <strong>12.3 Member Violations.</strong>
        If(i) you breach these Terms, our Policies, or our Standards, (ii) you
        violate applicable laws, regulations, or third - party rights, or(iii)
        OtherVenue believes it is reasonably necessary to protect OtherVenue,
        its Members, or third parties; OtherVenue may, with or without prior notice:
      </p>

      <div className={css.wrapNoMargin}>
        <p>
          • limit or suspend your access to or use of the OtherVenue Platform and / or your account;
        </p>

        <p>
          • suspend or remove Listings, Reviews, or other Content;
        </p>

        <p>
          • cancel pending or confirmed bookings; or
        </p>

        <p>
          • suspend or revoke any special status associated with your account.
        </p>
      </div>

      <p>
        For minor violations, or where OtherVenue otherwise deems appropriate,
        you will be provided notice of any intended action by OtherVenue and
        given an opportunity to resolve the issue.You may appeal actions taken
        by us under this Section by contacting our customer support team. If a
        reservation is cancelled under this Section, the amount paid to the
        Listing Owner will be reduced by the amount we refund or otherwise
        provide to the Guest, and by any other costs we incur as a result of
        the cancellation.
      </p>

      <p>
        <strong>12.4 Legal Mandates.</strong>
          OtherVenue may take any action it determines is reasonably necessary
          to comply with applicable law, or the order or request of a court,
          law enforcement, or other administrative agency or governmental body,
          including the actions described in these Terms.
      </p>


      <p>
        <strong>12.5 Effect of Termination.</strong>
        If you are a Listing Owner and terminate your OtherVenue account, any
        confirmed booking(s) will be automatically cancelled and your Guests
        will receive a full refund. If you terminate your account as a Guest,
        any confirmed booking(s) will be automatically cancelled and any refund
        will depend upon the terms of the reservation’s cancellation policy. When
        this agreement has been terminated, you are not entitled to a restoration
        of your account or any of your Content. If your access to or use of the
        OtherVenue Platform has been limited, or your OtherVenue account has been
        suspended, or this agreement has been terminated by us, you may not register
        a new account or access or use the OtherVenue Platform through an account of
        another Member.
      </p>


      <p>
        <strong>12.6 Survival.</strong>
        Parts of these Terms that by their nature survive termination,
        will survive termination of this agreement.
      </p>

      <p>
        <strong>13. Modification.</strong>
      </p>

      <p>
        OtherVenue may modify these Terms at any time. When we make material
        changes to these Terms, we will post the revised Terms on the OtherVenue
        Platform and update the “Last Updated” date at the top of these Terms. We
        will also provide you with notice of any material changes by email at
        least 30 days before the date they become effective. If you disagree with
        the revised Terms, you may terminate this agreement immediately as provided
        in these Terms. If you do not terminate your agreement before the date the
        revised Terms become effective, your continued access to or use of the
        OtherVenue Platform will constitute acceptance of the revised Terms.
      </p>


      <p><strong>14. Resolving Complaints and Damage Claims.</strong></p>
      <p>
        If a complaining Member has evidence and escalates a Damage
        Claim("Damage Claim") to OtherVenue, the other Member will be given
        an opportunity to respond. If the responding Member agrees to pay,
        or OtherVenue determines in its sole discretion that they are
        responsible for the Damage Claim, OtherVenue, via a third - party
        payment processor can collect any sums required to cover the Damage
        Claim from the responding Member and / or against any security deposit.
        You agree that OtherVenue may seek to recover from you under any insurance
        policies you maintain and that OtherVenue may also pursue against you
        any remedies it may have available under applicable law. You agree to
        cooperate in good faith, provide any information that OtherVenue requests,
        execute documents, and take further reasonable action, in connection with
        Damage Claims, Member complaints, claims under insurance policies, or other
        claims related to your provision or use of Services.
      </p>

      <p><strong>15. OtherVenue’s Role.</strong></p>
      <p>
        We offer a platform that enables Members to publish, offer, search for,
        and book venue space for events or activities. While we are working hard
        to ensure our Members have great experiences using OtherVenue, we do not
        and cannot control the conduct of Guests and Hosts. You acknowledge that
        OtherVenue has the right, but does not have any obligation, to monitor
        the use of the OtherVenue Platform and verify information provided by
        our Members. For example, we may review, disable access to, remove, or
        edit Content to: (i) operate, secure and improve the OtherVenue Platform
        (including for fraud prevention, risk assessment, investigation and customer
        support purposes); (ii) ensure Members’ compliance with these Terms; (iii)
        comply with applicable law or the order or requirement of a court, law
        enforcement or other administrative agency or governmental body; (iv) address
        Content that we determine is harmful or objectionable; (v) take actions set
        out in these Terms; and(vi) maintain and enforce any quality or eligibility
        criteria, including by removing Listings that don’t meet quality and eligibility
        criteria. Members acknowledge and agree that OtherVenue administers its Policies
        and Standards(such as basic requirements for hosts), including decisions about
        whether and how to apply them to a particular situation, at its sole discretion.
        Members agree to cooperate with and assist OtherVenue in good faith, and to
        provide OtherVenue with such information and take such actions as may be
        reasonably requested by OtherVenue with respect to any investigation undertaken
        by OtherVenue regarding the use or abuse of the OtherVenue Platform. OtherVenue
        is not acting as an agent for any Member except for where otherwise provided
        in these Terms.
      </p>

      <p><strong>16. Member Accounts.</strong></p>
      <p>
        You must register an account to access and use many features of the
        OtherVenue Platform. Registration is only permitted for legal entities,
        partnerships and natural persons who are 18 years or older. You represent
        and warrant that you are not a person or entity barred from using the
        OtherVenue Platform under the laws of the United States, your place of
        residence, or any other applicable jurisdiction. You must provide accurate,
        current, and complete information during registration and keep your account
        information up - to - date. You may not register more than one account or
        transfer your account to someone else. You are responsible for maintaining
        the confidentiality and security of your account credentials and may not
        disclose your credentials to any third party. You are responsible and liable
        for activities conducted through your account and must immediately notify
        OtherVenue if you suspect that your credentials have been lost, stolen, or
        your account is otherwise compromised. If and as permitted by applicable law,
        we may, but have no obligation to(i) ask you to provide identification or other
        information, (ii) undertake checks designed to help verify your identity or
        background, (iii) screen you against third - party databases or other sources
        and request reports from service providers, and(iv) obtain reports from public
        records of criminal convictions or sex offender registrations or their localequivalents.
      </p>


      <p><strong>17. Disclaimer of Warranties.</strong></p>
      <p>
        We provide the OtherVenue Platform and all Content “as is” without warranty
        of any kind and we disclaim all warranties, whether express or implied. For
        example: (i) we do not endorse or warrant the existence, conduct, performance,
        safety, quality, legality or suitability of any Guest, Service, Listing Owner,
        Listing or third party; (ii) we do not warrant the performance or non - interruption
        of the OtherVenue Platform; and(iii) we do not warrant that verification, identity
        or background checks conducted on Listings or Members(if any) will identify past
        misconduct or prevent future misconduct.Any references to a Member or Listing being
        "verified"(or similar language) indicate only that the Member or Listing or OtherVenue
        has completed a relevant verification or identification process and nothing else. The
        disclaimers in these Terms apply to the maximum extent permitted by law. If you have
        statutory rights or warranties we cannot disclaim, the duration of any such statutorily
        required rights or warranties, will be limited to the maximum extent permitted by law.
      </p>

      <p><strong>18. Limitations on Liability.</strong></p>
      <p>
        Neither OtherVenue(including any affiliates and personnel) nor any other
        party involved in creating, producing, or delivering the OtherVenue Platform
        or any Content will be liable for any incidental, special, exemplary or
        consequential damages, including lost profits, loss of data or loss of
        goodwill, service interruption, computer damage or system failure or the
        cost of substitute products or services, or for any damages for personal
        or bodily injury or emotional distress arising out of or in connection with
        (i) these Terms, (ii) the use of or inability to use the OtherVenue Platform
        or any Content, (iii) any communications, interactions or meetings you may
        have with someone you interact or meet with through, or as a result of, your
        use of the OtherVenue Platform, or(iv) publishing or booking of a Listing,
        including the provision or use of Services, whether based on warranty, contract,
        tort(including negligence), product liability or any other legal theory, and
        whether or not OtherVenue has been informed of the possibility of such damage,
        even if a limited remedy set out in these Terms is found to have failed of its
        essential purpose.
      </p>

      <p>
        Except for our obligation to transmit payments to Hosts under these Terms,
        in no event will OtherVenue’s aggregate liability for any claim or dispute
        arising out of or in connection with these Terms, your interaction with any
        Members, or your use of or inability to use the OtherVenue Platform, any Content,
        or any Service, exceed: (A) to Guests, the amount you paid as a Guest during the
        12 - month period prior to the event giving rise to the liability, (B) to Hosts,
        the amount paid to you as a Host in the 12 - month period prior to the event
        giving rise to the liability, or(C) to anyone else, one hundred U.S.dollars(US$100).
      </p>

      <p>
        These limitations of liability and damages are fundamental elements of the agreement
        between you and OtherVenue.If applicable law does not allow the limitations of
        liability set out in these Terms, the above limitations may not apply to you.
      </p>

      <p><strong>19. Indemnification.</strong></p>

      <p>
        To the maximum extent permitted by applicable law, you agree to release,
        defend(at OtherVenue's option), indemnify, and hold OtherVenue harmless
        from and against any claims, liabilities, damages, losses, and expenses,
        including, without limitation, reasonable legal and accounting fees, arising
        out of or in any way connected with: (i) your breach of these Terms (including
        any supplemental or additional terms that apply to a product or feature) or
        our Policies or Standards, (ii) your improper use of the OtherVenue Platform,
        (iii) your interaction with any Member, activity at Venue, or use of any Service,
        including without limitation any injuries, losses or damages (whether compensatory,
        direct, incidental, consequential or otherwise) of any kind arising in connection
        with or as a result of such interaction, stay, participation or use, (iv) your
        failure, or our failure at your direction, to accurately report, collect or remit
        Taxes, or (v) your breach of any laws, regulations or third party rights such as
        intellectual property or privacy rights.
      </p>


      <p><strong>20. United States Governing Law and Venue.</strong></p>

      <p>
        If you reside or have your place of establishment in the United States,
        these Terms will be interpreted in accordance with the laws of the State
        of California and the United States of America, without regard to conflict -
        of - law provisions. Judicial proceedings(other than small claims actions)
        that are excluded from the arbitration agreement in Section 23 must be brought
        in state or federal court in Portland, Oregon, unless we both agree to some other
        location. You and we both consent to venue and personal jurisdiction in Portland,
        Oregon.
      </p>

      <p>
        <strong>21. United States Dispute Resolution and Arbitration Agreement.</strong>
      </p>

      <p>
        <strong>21.1 Application.</strong>
        This Arbitration Agreement only applies to you if your country of residence
        or establishment is the United States. If your country of residence or
        establishment is not the United States, and you nevertheless attempt to
        bring any legal claim against OtherVenue in the United States, this Arbitration
        Agreement will apply for determination of the threshold issue of whether this
        Section 21 applies to you, and all other threshold determinations, including
        residency, arbitrability, venue, and applicable law.
      </p>


      <p>
        <strong>21.2 Overview of Dispute Resolution Process.</strong>
        These Terms provide for a two - part process for individuals to whom this
        Section 21 applies: (1) an informal negotiation directly with OtherVenue’s
        customer service team(described in paragraph 21.3, below), and if necessary(2)
        a binding arbitration administered by the American Arbitration Association(“AAA”).
        You and OtherVenue each retain the right to seek relief in small claims court
        as an alternative to arbitration.
      </p>


      <p>
        <strong>21.3 Mandatory Pre - Arbitration Dispute Resolution and Notification.</strong>
        At least 30 days prior to initiating an arbitration, you and OtherVenue each agree
        to notify the other party of the dispute in writing and attempt in good faith to
        negotiate an informal resolution. You must send your notice of dispute to OtherVenue
        by emailing it to out customer support at customersupport@othervenue.com. OtherVenue
        will send its notice of dispute to the email address associated with your OtherVenue
        account. A notice of dispute must include: the party’s name and preferred contact
        information, a brief description of the dispute, and the relief sought. If the parties
        are unable to resolve the dispute within the 30 - day period, only then may either
        party commence arbitration by filing a written Demand for Arbitration(available at
        www.adr.org) with the AAA and providing a copy to the other party as specified in
        the AAA Rules(available at www.adr.org).
      </p>

      <p>
        <strong>21.4 Agreement to Arbitrate.</strong>
        You and OtherVenue mutually agree that any dispute, claim or controversy arising
        out of or relating to these Terms or the applicability, breach, termination,
        validity, enforcement or interpretation thereof, or any use of the OtherVenue
        Platform, Services, or any Content(collectively, “Disputes”) will be settled by
        binding individual arbitration(the “Arbitration Agreement”).If there is a dispute
        about whether this Arbitration Agreement can be enforced or applies to our Dispute,
        you and OtherVenue agree that the arbitrator will decide that issue.
      </p>

      <p>
        <strong>21.5 Exceptions to Arbitration Agreement.</strong>
        You and OtherVenue each agree that the following causes of action and /
        or claims for relief are exceptions to the Arbitration Agreement and will
        be brought in a judicial proceeding in a court of competent jurisdiction
        (as defined by Section 20): (i) any claim or cause of action alleging actual
        or threatened infringement, misappropriation or violation of a party’s copyrights,
        trademarks, trade secrets, patents, or other intellectual property rights; (ii)
        any claim or cause of action seeking emergency injunctive relief based on exigent
        circumstances(e.g., imminent danger or commission of a crime, hacking, cyber - attack);
        or(iii) a request for the remedy of public injunctive relief. You and OtherVenue agree
        that the remedy of public injunctive relief will proceed after the arbitration of all
        arbitrable claims, remedies, or causes of action, and will be stayed pending the outcome
        of the arbitration pursuant to section 3 of the Federal Arbitration Act.
      </p>


      <p>
        <strong>21.6 Arbitration Rules and Governing Law.</strong>
        This Arbitration Agreement evidences a transaction in interstate commerce and the
        Federal Arbitration Act governs all substantive and procedural interpretation and
        enforcement of this provision. The arbitration will be administered by AAA in accordance
        with the Consumer Arbitration Rules and / or other AAA arbitration rules determined
        to be applicable by the AAA(the “AAA Rules“) then in effect, except as modified here.
        The AAA Rules are available at www.adr.org.In order to initiate arbitration, a
        completed written demand(available at www.adr.org) must be filed with the AAA and
        provided to the other party, as specified in the AAA rules.
      </p>


      <p>
        <strong>21.7 Modification to AAA Rules - Arbitration Hearing / Location.</strong>
        In order to make the arbitration most convenient to you, OtherVenue agrees that
        any required arbitration hearing may be conducted, at your option: (a) in the
        U.S.county where you reside; (b) in Multnomah County; (c) via phone or video
        conference; or(d) if all parties agree, by solely the submission of documents
        to the arbitrator.
      </p>

      <p>
        <strong>21.8 Modification of AAA Rules - Attorney’s Fees and Costs.</strong>
        Your arbitration fees and your share of arbitrator compensation shall be
        governed by the AAA Rules and, where appropriate, limited by the AAA Consumer
        Rules. Either party may make a request that the arbitrator award attorneys’
        fees and costs upon proving that the other party has asserted a claim,
        cross - claim or defense that is groundless in fact or law, brought in
        bad faith or for the purpose of harassment, or is otherwise frivolous,
        as allowed by applicable law and the AAA Rules.
      </p>

      <p>
        <strong>21.9 Arbitrator’s Decision.</strong>
        The arbitrator’s decision will include the essential findings and
        conclusions upon which the arbitrator based the award.Judgment on
        the arbitration award may be entered in any court with proper jurisdiction.
        The arbitrator may award any relief allowed by law or the AAA Rules, but
        declaratory or injunctive relief may be awarded only on an individual basis
        and only to the extent necessary to provide relief warranted by the
        claimant’s individual claim.
      </p>

      <p>
        <strong>21.10 Jury Trial Waiver.</strong>
        You and OtherVenue acknowledge and agree that we are each waiving the
        right to a trial by jury as to all arbitrable Disputes.
      </p>

      <p>
        <strong>21.11 No Class Actions or Representative Proceedings.</strong>
        You and OtherVenue acknowledge and agree that, to the fullest extent
        permitted by law, we are each waiving the right to participate as a
        plaintiff or class member in any purported class action lawsuit,
        class- wide arbitration, private attorney general action, or any
        other representative or consolidated proceeding. Unless we agree in
        writing, the arbitrator may not consolidate more than one party’s
        claims and may not otherwise preside over any form of any class or
        representative proceeding. If there is a final judicial determination
        that applicable law precludes enforcement of the waiver contained in
        this paragraph as to any claim, cause of action or requested remedy,
        then that claim, cause of action or requested remedy, and only that
        claim, cause of action or requested remedy, will be severed from this
        agreement to arbitrate and will be brought in a court of competent
        jurisdiction. In the event that a claim, cause of action or requested
        remedy is severed pursuant to this paragraph, then you and we agree
        that the claims, causes of action or requested remedies that are not
        subject to arbitration will be stayed until all arbitrable claims,
        causes of action and requested remedies are resolved by the arbitrator.
      </p>

      <p>
        <strong>21.12 Severability.</strong>
        Except as provided in Section 21.11, in the event that any portion
        of this Arbitration Agreement is deemed illegal or unenforceable,
        such provision will be severed and the remainder of the Arbitration
        Agreement will be given full force and effect.
      </p>

      <p>
        <strong>21.13 Changes to Agreement to Arbitrate.</strong>
        If OtherVenue changes this Section 21 after the date you last accepted
        these Terms(or accepted any subsequent changes to these Terms), you
        may reject that change by sending us written notice(including by email)
        within 30 days of the date the change is effective. Rejecting a new change,
        however, does not revoke or alter your prior consent to any earlier
        agreements to arbitrate any Dispute between you and OtherVenue(or
        your prior consent to any subsequent changes thereto), which will
        remain in effect and enforceable as to any Dispute between you and
        OtherVenue.
      </p>

      <p>
        <strong>21.14 Survival.</strong>
        Except as provided in Section 21.12 and subject to Section 12.6,
        this Section 21 will survive any termination of these Terms and will
        continue to apply even if you stop using the OtherVenue Platform or
        terminate your OtherVenue account.
      </p>

      <p>
        <strong>22. Rest of World Dispute Resolution, Venue and Forum, and Governing Law.</strong>
        If you reside or have your place of establishment outside of the
        United States and China, this Section applies to you and these Terms
        will be interpreted in accordance with Irish law. The application of
        the United Nations Convention on Contracts for the International Sale
        of Goods(CISG) is excluded. The choice of law does not impact your
        rights as a consumer according to the consumer protection regulations
        of your country of residence. If you are acting as a consumer, you agree
        to submit to the non - exclusive jurisdiction of the Irish courts. Legal
        proceedings that you are able to bring against us arising from or in
        connection with these Terms may only be brought in a court located in
        Ireland or a court with jurisdiction in your place of residence. If
        OtherVenue wishes to enforce any of its rights against you as a consumer,
        we may do so only in the courts of the jurisdiction in which you are a
        resident. If you are acting as a business, you agree to submit to the exclusive
        jurisdiction of the Irish courts.
      </p>

      <p>
        <strong>23. Miscellaneous.</strong>
      </p>

      <p>
        <strong>23.1 Other Terms Incorporated by Reference.</strong>
        Any and all other Policies, Standards and other supplemental policies
        and terms linked to in these Terms or otherwise published on the OtherVenue
        site apply to your use of the OtherVenue Platform, are incorporated by reference,
        and form part of your agreement with OtherVenue.
      </p>

      <p>
        <strong>23.2 Interpreting these Terms.</strong>
        Except as they may be supplemented by additional terms, conditions,
        policies, guidelines, standards, and in -product disclosures, these
        Terms(including those items incorporated by reference) constitute
        the entire agreement between OtherVenue and you pertaining to your
        access to or use of the OtherVenue Platform and supersede any and all
        prior oral or written understandings or agreements between OtherVenue
        and you. These Terms do not and are not intended to confer any rights or
        remedies upon anyone other than you and OtherVenue. If any provision of
        these Terms is held to be invalid or unenforceable, except as otherwise
        indicated in Section 21.11 above, such provision will be struck and will
        not affect the validity and enforceability of the remaining provisions.
        Where the word “will” is used in these Terms it connotes an obligation
        with the same meaning as “shall.”
      </p>

      <p>
        <strong>23.3 No Waiver.</strong>
        OtherVenue’s failure to enforce any right or provision in these
        Terms will not constitute a waiver of such right or provision
        unless acknowledged and agreed to by us in writing. Except as expressly
        set forth in these Terms, the exercise by either party of any of its
        remedies under these Terms will be without prejudice to its other
        remedies under these Terms or otherwise permitted under law.
      </p>

      <p>
        <strong>23.4 Assignment.</strong>
        You may not assign, transfer or delegate this agreement or your rights
        and obligations hereunder without OtherVenue's prior written consent.
        OtherVenue may without restriction assign, transfer or delegate this
        agreement and any rights and obligations hereunder, at its sole discretion,
        with 30 days’ prior notice.
      </p>

      <p>
        <strong>23.5 Notice.</strong>
        Unless specified otherwise, any notices or other communications to
        Members permitted or required under this agreement, will be provided
        electronically and given by OtherVenue via email, OtherVenue Platform
        notification, messaging service(including SMS and WeChat), or any other
        contact method we enable and you provide.
      </p>

      <p>
        <strong>23.6 Third - Party Services.</strong>
        The OtherVenue Platform may contain links to third - party websites,
        applications, services or resources(“Third - Party Services”) that
        are subject to different terms and privacy practices. OtherVenue is
        not responsible or liable for any aspect of such Third - Party Services
        and links to such Third - Party Services are not an endorsement.
      </p>

      <p>
        <strong>23.7 Google Terms.</strong>
        Some translations on the OtherVenue Platform are powered by Google.
        Google disclaims all warranties related to the translations, express or
        implied, including any warranties of accuracy, reliability, and any
        implied warranties for merchantability, fitness for a particular purpose
        and non - infringement. Some areas of the OtherVenue Platform may implement
        Google Maps / Earth mapping services, including Google Maps API(s). Your use of
        Google Maps / Earth is subject to the Google Maps / Google Earth Additional Terms of Service.
      </p>

      <p>
        <strong>23.8 Apple Terms.</strong>
        If you access or download our application from the Apple App Store,
        you agree to Apple’s Licensed Application End User License Agreement.
      </p>

      <p>
        <strong>23.9 OtherVenue Platform Content.</strong>
        Content made available through the OtherVenue Platform may be protected
        by copyright, trademark, and / or other laws of the United States and
        other countries. You acknowledge that all intellectual property rights
        for that Content are the exclusive property of OtherVenue and / or its
        licensors and agree that you will not remove, alter or obscure any copyright,
        trademark, service mark or other proprietary rights notices.You may not use,
        copy, adapt, modify, prepare derivative works of, distribute, license, sell,
        transfer, publicly display, publicly perform, transmit, broadcast or otherwise
        exploit any Content accessed through the OtherVenue Platform except to the extent
        you are the legal owner of that Content or as expressly permitted in these Terms.
        Subject to your compliance with these Terms, OtherVenue grants you a limited, non -
        exclusive, non - sublicensable, revocable, non - transferable license to(i) download
        and use the Application on your personal device(s); and(ii) access and view the Content
        made available on or through the OtherVenue Platform and accessible to you, solely for
        your personal and non - commercial use.
      </p>

      <p>
        <strong>23.10 Force Majeure.</strong>
        OtherVenue shall not be liable for any delay or failure to perform resulting
        from causes outside its reasonable control, including, but not limited to,
        acts of God, natural disasters, war, terrorism, riots, embargoes, acts of
        civil or military authorities, fire, floods, accidents, pandemics, epidemics
        or disease, strikes or shortages of transportation facilities, fuel, energy,
        labor or materials.
      </p>

      <p>
        <strong>23.11 Emails and SMS.</strong>
        You will receive administrative communications from us using the email
        address or other contact information you provide for your OtherVenue account.
        Enrollment in additional email subscription programs will not affect the
        frequency of these administrative emails, though you should expect to receive
        additional emails specific to the program(s) to which you have subscribed. You
        may also receive promotional emails from us. No fee is charged for these promotional
        emails, but third - party data rates could apply. You can control whether you receive
        promotional emails using the notification preferences in your account settings.
        Please note that you will not be able to take advantage of certain promotions
        if you disable certain communication settings or do not have an OtherVenue account.
        In the U.S.if you consent to receive SMS(text messages) from us, you will be subject
        to our SMS Terms.
      </p>

      <p>
        <strong>23.12 Contact Us.</strong>
        If you have any questions about these Terms please email us at support@othervenue.com.
      </p>

    </div >
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
