import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { DAILY_PRICE, LINE_ITEM_NIGHT, LINE_ITEM_UNITS, DATE_TYPE_DATE, propTypes } from '../../util/types';
import moment from 'moment';
import css from './BookingBreakdown.css';

const LineItemUnitsMaybe = props => {
  const { booking, dateType, timeZone, transaction, unitType, bookingType } = props;

  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  const localEndDateRaw = displayEnd || end;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isUnit = unitType === LINE_ITEM_UNITS;
  const endDay =
    isUnit || isNightly ? localEndDateRaw : moment(localEndDateRaw).subtract(1, 'days');

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
        weekday: 'long',
      }
      : {
        hour: 'numeric',
        minute: 'numeric',
      };

  const dateFormatOptions = {
    weekday: 'short',

    month: 'short',
    day: 'numeric',
  };


  const timeZoneMaybe = timeZone ? { timeZone } : null;

  if (unitType !== LINE_ITEM_UNITS) {
    return null;
  }

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  if (!unitPurchase) {
    throw new Error(`LineItemUnitsMaybe: lineItem (${unitType}) missing`);
  }

  const quantity = unitPurchase.quantity;

  return (
    <>
      <div className={css.lineItem}>
        <div className={css.itemLabel}>
          <FormattedMessage
            id="BookingBreakdown.dateTimeEvent"
            values={{
              date: <FormattedDate value={localStartDate} {...dateFormatOptions} {...timeZoneMaybe} />,
              timeStart: <FormattedDate value={localStartDate} {...timeFormatOptions} {...timeZoneMaybe} />,
              timeEnd: <FormattedDate value={endDay} {...timeFormatOptions} {...timeZoneMaybe} />,
            }}
          />
        </div>
        {bookingType !== DAILY_PRICE &&
          <span className={css.itemValue}>
            <FormattedMessage id="BookingBreakdown.quantity" values={{ quantity }} />
          </span>
        }
      </div >
      <hr className={css.totalDivider} />
    </>
  );
};

LineItemUnitsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

export default LineItemUnitsMaybe;
