import React from 'react';
import css from './AboutPage.css';
import { injectIntl, intlShape } from '../../util/reactIntl';

import weddingImg from '../../assets/wedding.jpg';
import shopImg from '../../assets/shop.jpg';
import outDoorWedding from '../../assets/outdoorWedding.jpg';
import { string } from 'prop-types';

const FunctionItem = (
  {
    intl,
    img,
    imgAlt,
    labelId,
    contentId
  }
) => {
  const alt = intl.formatMessage({id: imgAlt});
  const label = intl.formatMessage({id: labelId});
  const content = intl.formatMessage({id: contentId});
  return (
    <div className={css.functionItem}>
      <img className={css.functionItemImg} src={img} alt={alt} />
      <p className={css.functionItemLabel}>{label}</p>
      <p className={css.functionItemContent}>{content}</p>
    </div>
  )
};

FunctionItem.propTypes = {
  intl: intlShape.isRequired,
  img: string.isRequired,
  imgAlt: string.isRequired,
  labelId: string.isRequired,
  contentId: string.isRequired,
};

const SectionFunction = (
  {
    intl
  }
) => {
  const imgList = [weddingImg, shopImg, outDoorWedding];
  return (
    <div className={css.sectionWrapper}>
      <div className={css.functionSection}>
        <h2 className={css.functionTitle}>
          {intl.formatMessage({id: 'AboutPage.functionTitle'})}
        </h2>

        <div className={css.functionList}>
          {imgList.map((img, i) => (
            <FunctionItem
              key={i}
              intl={intl}
              img={img}
              imgAlt={`AboutPage.functionItemAlt${i+1}`}
              labelId={`AboutPage.functionItemLabel${i+1}`}
              contentId={`AboutPage.functionItemContent${i+1}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
};

SectionFunction.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SectionFunction)
