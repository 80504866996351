import React from 'react';
import css from './ListingPage.css';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import PropTypes from 'prop-types';
import { IconCheckmark } from '../../components';

const MINI_SIZE = 'mini';

const SectionCateringOptions = (
  {
    cateringType,
    cateringPackages
  }
) => {
  return (
    <div className={css.sectionCateringWrapper}>
      <h2 className={css.cateringTitle}>
        <FormattedMessage id="ListingPage.cateringTitle" />
      </h2>
      <p className={css.cateringSubTitle}>
        {config.custom.caterings.map(catering => catering.key === cateringType && catering.label)}
      </p>
      {cateringPackages && cateringPackages.map((p, index) => (
        <div key={index}>
          <span className={css.iconCheckMarkWrapper}>
            <IconCheckmark size={MINI_SIZE} rootClassName={css.iconCheckmark}/>
          </span>{p.name}
          <p className={css.packageDescription}>{p.description}</p>
        </div>
      ))}
    </div>
  )
};

const { array, string } = PropTypes;

SectionCateringOptions.propTypes = {
  cateringType: string,
  cateringPackages: array
};

export default SectionCateringOptions;
