import React from 'react';
import css from './ListingPage.css';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import PropTypes from 'prop-types';
import { IconCheckmark } from '../../components';
import { formatMoney } from '../../util/currency';
import { injectIntl } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isEmpty } from 'lodash';

const MINI_SIZE = 'mini';
const { Money } = sdkTypes;

const SectionRentableSupllies = (
  {
    rentalItems,
    intl
  }
) => {
  return rentalItems.length > 0 && !isEmpty(rentalItems[0]) && (
    <div className={css.sectionRentalWrapper}>
      <h2 className={css.rentalTitle}>
        <FormattedMessage id="ListingPage.rentalTitle" />
      </h2>

      {rentalItems && rentalItems.map((item, index) => {
        const { maxQuantityRental, rentalItem, rentalItemsLabel, descriptionRental, costPerRentalItem, renameRental } = item;
        const price = new Money(costPerRentalItem, config.currency);
        const priceTxt = formatMoney(intl, price);
        return (
          <div key={index}>
            <span className={css.iconCheckMarkWrapper}>
              <IconCheckmark rootClassName={css.iconCheckmark} size={MINI_SIZE} />
            </span>
            {maxQuantityRental} {renameRental || rentalItemsLabel} | {priceTxt} <FormattedMessage id={'ListingPage.eachTxt'} />
            <p className={css.packageDescription}>
              {descriptionRental}
            </p>
          </div>
        )
      })}
    </div>
  )
};

const { array } = PropTypes;

SectionRentableSupllies.propTypes = {
  rentalItems: array
};

export default injectIntl(SectionRentableSupllies);
