import React, { useState, useRef } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';
import { OutsideClickHandler } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './FieldDropdownCustom.css';

const CONTENT_MAX_LENGTH = 5000;

const FieldDropdownCustomComponent = props => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  /* eslint-disable no-unused-vars */
  const {
    rootClassName,
    className,
    inputRootClass,
    customErrorText,
    id,
    label,
    input,
    meta,
    onUnmount,
    isUncontrolled,
    inputRef,
    categories,
    placeholder,
    classNameSelected,
    classNameOpened,
    labelClass,
    ...rest
  } = props;
  /* eslint-enable no-unused-vars */
  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  // Textarea doesn't need type.
  const { type, ...inputWithoutType } = input;
  // Uncontrolled input uses defaultValue instead of value.
  const { value: defaultValue, ...inputWithoutValue } = input;
  // Use inputRef if it is passed as prop.
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses =
    inputRootClass ||
    classNames(css.input, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
    });

  const inputProps = { className: inputClasses, id, type, ...refMaybe, ...input, ...rest };
  const classSelect = !isSwitchOn ?
    (classNames(css.fauxSelect, classNameSelected)) :
    classNames(css.fauxSelect, css.fauxSelectOpen, classNameSelected);
  const classOption = !isSwitchOn ? css.options : classNames(css.options, css.open, classNameOpened);
  const classes = classNames(rootClassName || css.root, className);

  const handleBlur = () => {
    setIsOpen(false);
    setIsSwitchOn(false);
  }

  const toggleOpen = () => {
    setIsSwitchOn(!isSwitchOn);
    setIsOpen(!isOpen);
  }

  const categoryLabel = (categories, key) => {
    const cat = categories.find(c => c.key === key);
    return cat ? cat.label : key;
  };


  const classInputPlaceholder = !props.input.value ? css.placeholderRequired : css.placeholder;
  const classInput = classNames(inputClasses, classInputPlaceholder)

  return (
    <div className={classes}>
      {label ? <label className={labelClass} htmlFor={id}>{label}</label> : null}
      <div className={css.centerTable}>
        <div id="select-box">
          <OutsideClickHandler onOutsideClick={handleBlur}>
            <ul
              className={classSelect}
              data-selected-value="indoor"
              onClick={toggleOpen}
            >
              <li className={css.selectedOption}>
                <input placeholder={placeholder} {...inputProps} className={classInput} />
                <span className={css.selectedItem}>
                  {categoryLabel(categories, props.input.value)}
                </span>
                {isOpen &&
                  <ul className={classOption} >
                    {categories.map(c => (
                      <li data-value={c.key}   >
                        <div className={css.inputGroup}>
                          <Field
                            name={inputProps.name}
                            className={css.rentalOption}
                            component="input"
                            type="radio"
                            value={c.key}
                          />
                          <label>{c.label}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                }
              </li>
            </ul>
          </OutsideClickHandler>
        </div>
      </div>
      <ValidationError fieldMeta={fieldMeta} />
      <div>
      </div>
    </div>
  );
}

FieldDropdownCustomComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldDropdownCustomComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

const FieldDropdownCustom = props => {
  return <Field component={FieldDropdownCustomComponent} {...props} />;
}

export default FieldDropdownCustom;