import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_SUPPLIES } from '../../util/types';
import { lineItemsTotal } from '../../util/lineItems';
import classNames from 'classnames';
import { OTHER } from '../../marketplace-custom-config';
import css from './BookingBreakdown.css';


const LineItemSupplyRentalMaybe = props => {
  const { transaction, intl } = props;
  const supplyLineItems = transaction.attributes.lineItems.filter(
    item => item.code === LINE_ITEM_SUPPLIES && !item.reversal
  );

  if (supplyLineItems.length === 0) {
    return null;
  }

  const subTotal = lineItemsTotal(supplyLineItems);
  const formattedSubTotal = supplyLineItems.length > 0 ? formatMoney(intl, subTotal) : null;


  const { protectedData } = transaction.attributes;
  const { suppliesDataMaybe = [] } = protectedData || {};

  return (
    <div className={css.wrapSupply}>
      <p className={css.titleSection}>
        <FormattedMessage id="BookingBreakdown.sectionSupply" />
      </p>
      {
        supplyLineItems.map((item, index) => {
          const price = formatMoney(intl, item.lineTotal);
          const label = intl.formatMessage(
            { id: 'BookingBreakdown.labelCatering' },
            {
              name: suppliesDataMaybe[index].rentalItem !== OTHER
                ? suppliesDataMaybe[index].rentalItemsLabel
                : suppliesDataMaybe[index].renameRental,
              quantity: suppliesDataMaybe[index].quantity
            }
          );
          return (
            <div className={classNames(css.lineItem, css.lineItemCustomPricing)} key={index}>
              <span className={css.itemLabel}>{label}</span>
              <span className={css.itemValue}>{price}</span>
            </div>
          );
        })
      }

      <hr className={css.totalDivider} />

      <div className={css.lineItem}>
        <span className={css.itemLabelBold}>
          <FormattedMessage id="BookingBreakdown.subTotal" />
        </span>
        <span className={css.itemValueBold}>{formattedSubTotal}</span>
      </div>
    </div>
  );
};

LineItemSupplyRentalMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSupplyRentalMaybe;
