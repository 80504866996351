import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { fetchListingNearlyCurrentLocation } from './LandingPage.duck';

import facebookImage from '../../assets/background-1440.jpg';
import twitterImage from '../../assets/background-1440.jpg';
import css from './LandingPage.css';
import { userLocation } from '../../util/maps';
import { FormattedMessage } from 'react-intl';

const GENERATED_BOUNDS_DEFAULT_DISTANCE = 80467.2; // meters ~ 50 miles

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    onFetchListingNearlyCurrentLocation,
    locations
  } = props;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;


  useEffect(() => {
    function fetchCurrentLocation() {
      return userLocation().then(latlng => {
        const bounds = new window.mapboxgl.LngLat(latlng.lng, latlng.lat)
          .toBounds(GENERATED_BOUNDS_DEFAULT_DISTANCE);
        return onFetchListingNearlyCurrentLocation(bounds);
      });
    }
    fetchCurrentLocation();
  }, []);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            {locations.length > 0 &&
              <li className={css.section}>
                <div className={css.sectionContentFirstChild}>
                  <div className={css.titleLocation}>
                    <FormattedMessage id="SectionLocations.title" />
                  </div>
                  <div className={css.wrapLocations}>
                    {locations.map((location, index) => (
                      <SectionLocations location={location} intl={intl} key={`${index}-item-location`} />
                    ))
                    }
                  </div>

                </div>
              </li>
            }
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    locations
  } = state.LandingPage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    locations
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchListingNearlyCurrentLocation: (bounds) =>
    dispatch(fetchListingNearlyCurrentLocation(bounds)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps, mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
